import { uniqueId } from 'lodash';

import {
   SendPalletsItem,
   SendPalletsItemsErrors,
   SendPalletsItemsTouched,
} from 'interfaces/sendPalletsForm';

import {
   LOST_DAMAGED_PALLETS_ITEM_PREFIX,
   PALLETS_ITEM_PREFIX,
   SEND_PALLETS_ITEM_PREFIX,
} from './uniqueIdPrefixes';
import { EMPTY_INPUT_ERROR } from './validationErrors';

export const LOST_DAMAGED_PALLETS_BY_SUPPLIER_DEFAULT_ITEM = () => ({
   uuid: uniqueId(LOST_DAMAGED_PALLETS_ITEM_PREFIX),
   palletType: { value: '', label: '' },
   damageType: null,
   palletsAmount: '',
   images: [],
   imageURLs: [],
});

export const LOST_DAMAGED_PALLETS_BY_CLIENT_DEFAULT_ITEM = () => ({
   uuid: uniqueId(LOST_DAMAGED_PALLETS_ITEM_PREFIX),
   palletType: { value: '', label: '' },
   damageType: null,
   palletsAmount: '',
});

export const LOST_DAMAGED_PALLETS_BY_CLIENT_DEFAULT_ITEM2 = () => ({
   uuid: uniqueId(LOST_DAMAGED_PALLETS_ITEM_PREFIX),
   palletType: { value: '', label: '' },
   damageType: null,
   palletsAmount: '',
   palletPrice: '',
});

export const DEFAULT_SEND_PALLETS_ITEM: () => SendPalletsItem = () => ({
   uuid: uniqueId(SEND_PALLETS_ITEM_PREFIX),
   sendDate: new Date(),
   externalCommercialNetworkNumber: '',
   commercialNetworkName: undefined,
   commercialNetworkAddress: { value: '', label: '' },
   palletsItems: [DEFAULT_PALLETS_ITEM()],
});

export const DEFAULT_SEND_PALLETS_ITEMS_ERRORS: () => SendPalletsItemsErrors = () => ({
   sendDate: false,
   externalCommercialNetworkNumber: EMPTY_INPUT_ERROR,
   commercialNetworkName: EMPTY_INPUT_ERROR,
   commercialNetworkAddress: EMPTY_INPUT_ERROR,
   palletsItems: [{ palletType: EMPTY_INPUT_ERROR, palletsAmount: EMPTY_INPUT_ERROR }],
});
type SendPalletsItems = SendPalletsItemsTouched | SendPalletsItemsErrors;

const DEFAULT_EDIT_PALLETS_ITEM = <T extends SendPalletsItems>(length: number): T =>
   ({
      includesDamagedPallets: false,
      sendDate: false,
      externalCommercialNetworkNumber: false,
      commercialNetworkName: false,
      commercialNetworkAddress: false,
      palletsItems: Array.from({ length }, () => ({
         palletType: false,
         palletsAmount: false,
      })),
   } as T);

export const DEFAULT_EDIT_PALLETS_ITEMS_ERRORS: (
   palletItemsCount?: number,
) => SendPalletsItemsErrors = (palletItemsCount?: number) => {
   if (palletItemsCount && palletItemsCount > 1) {
      return DEFAULT_EDIT_PALLETS_ITEM(palletItemsCount);
   }
   return DEFAULT_EDIT_PALLETS_ITEM(1);
};

export const DEFAULT_SEND_PALLETS_ITEMS_TOUCHED: () => SendPalletsItemsTouched = () => ({
   sendDate: false,
   externalCommercialNetworkNumber: false,
   commercialNetworkName: false,
   commercialNetworkAddress: false,
   palletsItems: [{ palletType: false, palletsAmount: false }],
});
export const DEFAULT_EDIT_PALLETS_ITEMS_TOUCHED: (
   palletItemsCount?: number,
) => SendPalletsItemsTouched = (palletItemsCount?: number) => {
   if (palletItemsCount && palletItemsCount > 1) {
      return DEFAULT_EDIT_PALLETS_ITEM(palletItemsCount);
   }

   return DEFAULT_EDIT_PALLETS_ITEM(1);
};

export const DEFAULT_PALLETS_ITEM = () => ({
   uuid: uniqueId(PALLETS_ITEM_PREFIX),
   palletType: { value: '', label: '' },
   palletsAmount: '',
});
