import { ReactNode } from 'react';

import { ToolTip } from 'components/shared';

import styles from './FinishEditingSectionButtonTooltip.module.css';

type FinishEditingSectionButtonTooltipProps = {
   children: ReactNode;
   isVisible: boolean;
};

const FinishEditingSectionButtonTooltip = ({
   children,
   isVisible,
}: FinishEditingSectionButtonTooltipProps) => (
   <ToolTip
      content="Zakończ edycję sekcji aby kontunuować"
      variant="top"
      className={styles.tooltip}
      visible={isVisible}
   >
      {children}
   </ToolTip>
);

export default FinishEditingSectionButtonTooltip;
