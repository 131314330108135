import { TableHeader } from 'storybook';

export const clientOrdersTableHeaders: {
   label: string;
   field: string;
   sortable?: boolean;
   align?: 'left' | 'right' | 'center';
}[] = [
   { field: 'orderId', label: 'ID zamówienia' },
   { field: 'internalOrderId', label: 'Nr wewn.' },
   { field: 'orderDate', label: 'Data złożenia zamówienia' },
   { field: 'deliveredDate', label: 'Dzień dostarczenia palet' },
   { field: 'sendPalletsDate', label: 'Data nadania palet' },
   { field: 'status', label: 'Status zamówienia' },
   { field: 'palletsAmount', label: 'Ilość palet', align: 'right' },
   { field: 'actions', label: 'Akcje' },
];

export const clientOrdersTableHeadersWithTags: {
   label: string;
   field: string;
   sortable?: boolean;
   align?: 'left' | 'right' | 'center';
}[] = [{ field: 'tags', label: '' }, ...clientOrdersTableHeaders];

export const supplierOrdersTableHeaders: {
   label: string;
   field: string;
   sortable?: boolean;
   align?: 'left' | 'right' | 'center';
}[] = [
   { field: 'orderId', label: 'ID' },
   { field: 'clientName', label: 'Nazwa firmy' },
   { field: 'orderDate', label: 'Data złożenia zamówienia' },
   { field: 'deliveredDate', label: 'Data dostarczenia palet' },
   { field: 'shipmentDate', label: 'Data nadania palet' },
   { field: 'status', label: 'Status zamówienia' },
   { field: 'palletsAmount', label: 'Ilość palet', align: 'right' },
   { field: 'actions', label: 'Akcje' },
];

export const distributionCenterOrdersTableHeaders: {
   label: string;
   field: string;
   sortable?: boolean;
   align?: 'left' | 'right' | 'center';
}[] = [
   { field: 'externalCommercialNetworkNumber', label: 'Nr. zam. sieci' },
   { field: 'orderId', label: 'ID' },
   { field: 'clientName', label: 'Nazwa firmy' },
   { field: 'shipmentDate', label: 'Data nadania palet' },
   { field: 'palletsAmount', label: 'Ilość palet' },
   { field: '', label: '' },
   { field: 'actions', label: 'Akcje' },
];

export const supplierOrdersTableHeadersWithTags: {
   label: string;
   field: string;
   sortable?: boolean;
   align?: 'left' | 'right' | 'center';
}[] = [{ field: 'tags', label: '' }, ...supplierOrdersTableHeaders];

export const lostDamagedPalletsByClientTableHeaders = [
   { field: 'palletType', label: 'Typ palety' },
   { field: 'damageType', label: 'Rodzaj uszkodzenia' },
   { field: 'palletsAmount', label: 'Ilość palet' },
   { field: 'removeItemBtn', label: '' },
];

export const lostDamagedPalletsBySupplierTableHeaders = [
   { field: 'palletType', label: 'Typ palety' },
   { field: 'damageType', label: 'Rodzaj uszkodzenia' },
   { field: 'palletsAmount', label: 'Ilość palet' },
   { field: 'images', label: 'Zdjęcia' },
   { field: 'removeItemBtn', label: '' },
];

export const sendDamagedPalletsTableHeaders = [
   { field: 'warningIcon', label: '' },
   { field: 'sendDate', label: 'Data nadania' },
   { field: 'externalCommercialNetworkNumber', label: 'Nr zam. sieci' },
   { field: 'commercialNetworkName', label: 'Miejsce nadania' },
   { field: 'commercialNetworkAddress', label: 'Adres nadania' },
   { field: 'palletType', label: 'Typ palety' },
   { field: 'palletsAmount', label: 'Ilość palet' },
   { field: 'removeItemBtn', label: '' },
];

export const sendPalletsTableHeaders = [
   { field: 'sendDate', label: 'Data nadania' },
   { field: 'externalCommercialNetworkNumber', label: 'Nr zam. sieci' },
   { field: 'commercialNetworkName', label: 'Miejsce nadania' },
   { field: 'commercialNetworkAddress', label: 'Adres nadania' },
   { field: 'palletType', label: 'Typ palety' },
   { field: 'palletsAmount', label: 'Ilość palet' },
   { field: 'removeItemBtn', label: '' },
];

export const editPalletsTableHeaders = [
   { field: 'damaged', label: 'Uszkodzone' },
   { field: 'sendDate', label: 'Data nadania' },
   { field: 'externalCommercialNetworkNumber', label: 'Nr zam. sieci' },
   { field: 'commercialNetworkName', label: 'Miejsce nadania' },
   { field: 'commercialNetworkAddress', label: 'Adres nadania' },
   { field: 'palletType', label: 'Typ palety' },
   { field: 'palletsAmount', label: 'Ilość palet' },
   { field: 'removeItemBtn', label: '' },
];

export const damagedDestroyedLostPalletsByClientTableHeaders: TableHeader[] = [
   { field: 'orderId', label: 'Numer zamówienia' },
   { field: 'damageType', label: 'Rodzaj uszkodzenia' },
   { field: 'palletType', label: 'Typ palety' },
   { field: 'palletsAmount', label: 'Ilość palet', align: 'right' },
];

export const lostDamagedPalletsInDeliveryClientTableHeaders: TableHeader[] = [
   { field: 'orderId', label: 'Nr zamówienia' },
   { field: 'reportStatus', label: 'Status zgłoszenia' },
   { field: 'damageType', label: 'Rodzaj uszkodzenia' },
   { field: 'palletType', label: 'Typ palety' },
   { field: 'palletsAmount', label: 'Ilość palet', align: 'right' },
   { field: 'images', label: 'Zdjęcia', align: 'right' },
];

export const lostDamagedPalletsSupplierOrderDetailsTableHeaders: TableHeader[] = [
   { field: 'reportStatus', label: 'Status zgłoszenia' },
   { field: 'palletType', label: 'Typ palety' },
   { field: 'palletsAmount', label: 'Ilość palet', align: 'right' },
   { field: 'images', label: 'Zdjęcia', align: 'right' },
];

export const lostDamagedPalletsSupplierReportDetailsTableHeaders: TableHeader[] = [
   { field: 'palletType', label: 'Typ palety' },
   { field: 'palletsAmount', label: 'Ilość palet', align: 'right' },
   { field: 'images', label: 'Zdjęcia', align: 'right' },
];

export const clientsTableHeaders = [
   { field: 'blocked', label: '' },
   { field: 'name', label: 'Nazwa firmy' },
   { field: 'nip', label: 'NIP' },
   { field: 'contactEmail', label: 'E-mail kontaktowy' },
   { field: 'phoneNumber', label: 'Telefon' },
   { field: 'actions', label: 'Akcje' },
];

export const additionalCostsClientTableHeaders: TableHeader[] = [
   { field: 'name', label: 'Typ palety' },
   { field: 'type', label: 'Rodzaj obciążenia' },
   { field: 'period', label: 'Okres przetrzymania' },
   { field: 'palletsAmount', label: 'Ilość palet (szt.)', align: 'right' },
];

export const additionalCostsSupplierTableHeaders: TableHeader[] = [
   { field: 'name', label: 'Typ palety' },
   { field: 'type', label: 'Rodzaj obciążenia' },
   { field: 'period', label: 'Okres przetrzymania' },
   { field: 'palletsAmount', label: 'Ilość palet (szt.)', align: 'right' },
   { field: 'chargePrice', label: 'Kwota kary', align: 'right' },
   { field: 'totalChargesAmount', label: 'Należność (PLN)', align: 'right' },
];

export const sendPalletsSectionTableHeaders: TableHeader[] = [
   { field: 'shipmentDate', label: 'Data nadania' },
   { field: 'orderNumber', label: 'Nr zam. sieci' },
   { field: 'companyName', label: 'Miejsce nadania' },
   { field: 'address', label: 'Adres nadania' },
   { field: 'name', label: 'Typ palety' },
   { field: 'amount', label: 'Ilość palet', align: 'right' },
];

export const sendPalletsSectionTableHeadersWithDamagedPallets: TableHeader[] = [
   { field: 'sendDamagedPalletsAlert', label: '' },
   ...sendPalletsSectionTableHeaders,
];

export const commentsTableHeaders: TableHeader[] = [
   { field: 'date', label: 'Data' },
   { field: 'content', label: 'Treść' },
   { field: 'actions', label: 'Akcje', align: 'right' },
];

export const documentsTableHeaders: TableHeader[] = [
   { field: 'name', label: 'Nazwa dokumentu' },
   { field: 'createdAt', label: 'Data utworzenia' },
   { field: 'actions', label: 'Akcje', align: 'right' },
];

export const documentsTableHeadersWithCanceledInvoices: TableHeader[] = [
   { field: 'canceledInvoiceAlert', label: '' },
   ...documentsTableHeaders,
];

export const commonPalletsPricingTableHeaders = [
   'Rodzaj palety',
   'Cena z odbiorem osob.',
   'Uszkodzenie',
   'Zgubienie /zniszczenie',
   'Wysłanie do innego odbiorcy',
];

export const palletsPricingPerAddressTableHeaders = ['Rodzaj palety', 'Cena z dostawą'];

export const commercialNetworksTableHeaders = [
   { field: 'commercialNetworkName', label: 'Nazwa Sieci Handlowej' },
   { field: 'commercialNetworkAddress', label: 'Adres Centrum Dystrybucyjnego' },
   { field: 'removeRowButton', label: '' },
];
export const externalStorageTableHeaders: TableHeader[] = [
   { field: 'name', label: 'Rodzaj palety' },
   { field: 'readyToRent', label: 'Gotowe do wynajmu', align: 'right' },
   { field: 'damaged', label: 'Uszkodzone', align: 'right' },
];

export const internalStorageTableHeaders: TableHeader[] = [
   ...externalStorageTableHeaders,
   { field: 'lost', label: 'Stracone', align: 'right' },
];

export const supplierPalletsTableHeaders: TableHeader[] = [
   { field: 'tags', label: '' },
   { field: 'orderId', label: 'Nr zam.' },
   { field: 'clientName', label: 'Nazwa firmy' },
   { field: 'orderDate', label: 'Data zgłoszenia' },
   { field: 'deliveredDate', label: 'Status zgłoszenia' },
   { field: 'shipmentDate', label: 'Ilość palet', align: 'right' },
   { field: 'status', label: 'Rodzaj uszkodzenia' },
   { field: 'actions', label: 'Akcje', align: 'right' },
];

export const commercialNetworkTableHeaders: TableHeader[] = [
   { field: 'name', label: 'Nazwa SH' },
   { field: 'address', label: 'Adres Centrum Dystrybucyjnego' },
   { field: 'collect all', label: 'Do odbioru łącznie', align: 'right' },
   { field: 'collect valid', label: 'Do odbioru dobre', align: 'right' },
   { field: 'collect invalid', label: 'Do odbioru uszkodzone', align: 'right' },
   { field: 'return all', label: 'Zwroty łącznie', align: 'right' },
   { field: 'return valid', label: 'Zwroty dobre', align: 'right' },
   { field: 'return invalid', label: 'Zwroty uszkodzone', align: 'right' },
   { field: 'actions', label: 'Akcje', align: 'right' },
];

export const pickUpsTableHeaders: TableHeader[] = [
   { field: 'date', label: 'Data odbioru' },
   { field: 'hour', label: 'Godzina' },
   { field: 'pallet types', label: 'Rodzaj palet do odbioru' },
   { field: 'collect all', label: 'Do odbioru łącznie', align: 'right' },
   { field: 'collect valid', label: 'Do odbioru dobre', align: 'right' },
   { field: 'collect invalid', label: 'Do odbioru uszkodzone', align: 'right' },
];

export const distributionCenterPalletsTableHeaders = [
   { field: 'name', label: 'Rodzaj palety' },
   { field: 'collect all', label: 'Do odbioru łącznie' },
   { field: 'collect valid', label: 'Do odbioru dobre' },
   { field: 'collect invalid', label: 'Do odbioru uszkodzone' },
   { field: 'return all', label: 'Zwroty łącznie' },
   { field: 'return valid', label: 'Zwroty dobre' },
   { field: 'return invalid', label: 'Zwroty uszkodzone' },
];

export const panelDistributionCenterPalletsTableHeaders = [
   { field: 'name', label: 'Rodzaj palety' },
   { field: 'balance all', label: 'Saldo łącznie' },
];

export const distributionCenterPalletsTableHeadersChangeOrder = [
   { field: 'name', label: 'Rodzaj palety' },
   { field: 'return valid', label: 'Zwroty dobre' },
   { field: 'return invalid', label: 'Zwroty uszkodzone' },
   { field: 'return all', label: 'Zwroty łącznie' },
   { field: 'collect valid', label: 'Do odbioru dobre' },
   { field: 'collect invalid', label: 'Do odbioru uszkodzone' },
   { field: 'collect all', label: 'Do odbioru łącznie' },
];

export const distributionCenterPalletsTransferTableHeaders = [
   { field: 'name', label: 'Rodzaj palety' },
   { field: 'collect valid', label: 'Do odbioru dobre' },
   { field: 'collect invalid', label: 'Do odbioru uszkodzone' },
   { field: 'collect all', label: 'Do odbioru łącznie' },
];

export const editUserBalanceHeaders: TableHeader[] = [
   { field: 'name', label: 'Rodzaj palety' },
   { field: 'rented', label: 'W użyciu', align: 'right' },
   { field: 'lost by user fault', label: 'Zgubione / zniszczone z winy klienta', align: 'right' },
   { field: 'damaged by user fault', label: 'Uszkodzone z winy klienta', align: 'right' },
   { field: 'received damaged', label: 'Dostarczone zniszczone palety', align: 'right' },
   { field: 'sum', label: 'Saldo razem', align: 'right' },
];

export const clientPalletBalanceTableHeaders: TableHeader[] = [
   { field: 'companyName', label: 'Nazwa firmy' },
   { field: 'palletBalance', label: 'Saldo palet', align: 'right' },
   { field: 'edhpPallet', label: 'Paleta EDHP', additionalInfo: '800x1200 mm', align: 'right' },
   { field: 'euroPallet', label: 'Euro paleta', additionalInfo: '800x1200 mm', align: 'right' },
   {
      field: 'halfPalletNDHP',
      label: 'Półpaleta NDHP',
      additionalInfo: '600x800 mm',
      align: 'right',
   },
   {
      field: 'quarterPalletCDHP',
      label: 'Ćwierćpaleta CDHP',
      additionalInfo: '400x600 mm',
      align: 'right',
   },
   { field: '', label: 'Akcje', align: 'right' },
];
