import React from 'react';

import classNames from 'classnames';

import styles from './ToolTip.module.css';

type ToolTipProps = {
   children: React.ReactNode;
   content: string;
   className?: string;
   visible?: boolean;
   variant?: 'right' | 'left' | 'top' | 'bottom';
};

const ToolTip = ({ children, content, className, visible, variant }: ToolTipProps) => {
   return (
      <div className={classNames(styles.toolTipWrapper, { [styles.visible]: visible }, className)}>
         {children}
         <div
            className={classNames(styles.toolTipContent, {
               [styles.visible]: visible,
               [styles.right]: variant === 'right',
               [styles.left]: variant === 'left',
               [styles.top]: variant === 'top',
               [styles.bottom]: variant === 'bottom',
            })}
         >
            {content}
         </div>
      </div>
   );
};

export default ToolTip;
