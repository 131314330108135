import { ChangeEvent } from 'react';

import StyledButton from 'components/shared/StyledButton/StyledButton';
import { DEFAULT_SEND_PALLETS_ITEM } from 'constants/palletsTableDefaultValues';
import { Option } from 'interfaces/commonInterfacesAndTypes';
import {
   SendPalletsItem,
   SendPalletsItemsErrors,
   SendPalletsItemsTouched,
} from 'interfaces/sendPalletsForm';

import SendPalletsItemCard from './SendPalletsItemCard/SendPalletsItemCard';

type SendPalletsMobileListProps = {
   sendPalletsItems: SendPalletsItem[];
   sendPalletsItemsErrors: SendPalletsItemsErrors[];
   sendPalletsItemsTouched: SendPalletsItemsTouched[];
   destinationNameOptions: Option[];
   destinationAddressOptions: {
      [key: string]: Option[];
   };
   availablePalletTypes: Option[];
   onAddSendPalletsItem: (newItem: SendPalletsItem) => void;
   onAddPalletsItem: (sendPalletsItemIndex: number) => void;
   onRemoveSendPalletsItem: (sendPalletsItemIndex: number) => void;
   onRemovePalletsItem: (sendPalletsItemIndex: number, palletsItemIndex: number) => void;
   onChangeSendPalletsItem: (
      sendPalletsItemIndex: number,
      field: string,
      value: string | Date | null | Option,
      sendPalletsItem: SendPalletsItem,
      clearTouchedState?: boolean,
   ) => void;
   onChangePalletsItem: (
      sendPalletsItemIndex: number,
      palletsItemIndex: number,
      field: string,
      value: string | Option,
   ) => void;
   onAddCommercialNetwork: (newCommercialNetwork: string) => void;
   onAddCommercialNetworkAddress: (
      commercialNetwork: string,
      newCommercialNetworkAddress: string,
   ) => void;
   sendDamagedPalletsMode?: boolean;
   isModalEditMode: boolean;
   onChangeIncludesDamagedPallets: (
      rowIndex: number,
      value: React.MouseEvent<Element, MouseEvent> & ChangeEvent<HTMLInputElement>,
      sendPalletsItem: SendPalletsItem,
   ) => void;
};

const SendPalletsMobileList = ({
   sendPalletsItems,
   sendPalletsItemsErrors,
   sendPalletsItemsTouched,
   destinationNameOptions,
   destinationAddressOptions,
   availablePalletTypes,
   onAddSendPalletsItem,
   onAddPalletsItem,
   onRemoveSendPalletsItem,
   onRemovePalletsItem,
   onChangeSendPalletsItem,
   onChangePalletsItem,
   onAddCommercialNetwork,
   onAddCommercialNetworkAddress,
   sendDamagedPalletsMode,
   isModalEditMode,
   onChangeIncludesDamagedPallets,
}: SendPalletsMobileListProps) => (
   <div>
      {sendPalletsItems.map((sendPalletsItem, index) => (
         <SendPalletsItemCard
            key={sendPalletsItem.uuid}
            index={index}
            item={sendPalletsItems[index]}
            errors={sendPalletsItemsErrors[index]}
            touched={sendPalletsItemsTouched[index]}
            destinationNameOptions={destinationNameOptions}
            destinationAddressOptions={destinationAddressOptions}
            availablePalletTypes={availablePalletTypes}
            onChangeItem={onChangeSendPalletsItem}
            onAddPalletsItem={onAddPalletsItem}
            onRemovePalletsItem={onRemovePalletsItem}
            onChangePalletsItem={onChangePalletsItem}
            onRemoveItem={onRemoveSendPalletsItem}
            onAddCommercialNetwork={onAddCommercialNetwork}
            onAddCommercialNetworkAddress={onAddCommercialNetworkAddress}
            sendDamagedPalletsMode={sendDamagedPalletsMode}
            isModalEditMode={isModalEditMode}
            onChangeIncludesDamagedPallets={onChangeIncludesDamagedPallets}
         />
      ))}
      <StyledButton
         type="button"
         onClick={() => onAddSendPalletsItem(DEFAULT_SEND_PALLETS_ITEM())}
         text="Dodaj kolejne nadanie"
         variant="outlined-primary"
      />
   </div>
);

export default SendPalletsMobileList;
