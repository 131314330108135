import { StoragePageTab, SupplierOrdersPageTab } from 'interfaces';

export const supplierOrdersPageTabs: { label: string; value: SupplierOrdersPageTab }[] = [
   { label: 'Wszystkie', value: 'ALL' },
   { label: 'Oczekujące', value: 'WAITING' },
   { label: 'W realizacji', value: 'IN_PROGRESS' },
   { label: 'Dostarczone', value: 'DELIVERED' },
   { label: 'Rozliczane', value: 'DURING_BILLING' },
   { label: 'Do zapłaty', value: 'TO_PAY' },
   { label: 'Archiwalne', value: 'ARCHIVED' },
];

export const storagePageTabs: { label: string; value: StoragePageTab }[] = [
   { label: 'Magazyny wewnętrzne', value: 'internal-storages' },
   { label: 'Magazyny zewnętrzne', value: 'external-storages' },
   { label: 'Sieci handlowe', value: 'commercial-networks' },
   { label: 'Klienci', value: 'clients' },
];
