import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';

import classNames from 'classnames';
import { BuildingWarehouse, History, Tag, TruckReturn } from 'tabler-icons-react';

import {
   PalletGreenIcon,
   PalletIcon,
   PalletOutlineIcon,
   PlusWhiteIcon,
   UsersGreenIcon,
   UsersIcon,
} from 'assets';
import { Logo } from 'components/shared';
import {
   CLIENT_DAMAGED_PALLETS_BY_CLIENT,
   CLIENT_DESTROYED_PALLETS_BY_SUPPLIER,
   CLIENT_LOST_DESTROYED_PALLETS_BY_CLIENT,
   CLIENT_ORDERS,
   CLIENT_PALLETS,
   CLIENT_PALLETS_TRANSFER_HISTORY,
   CLIENT_PLACE_ORDER,
   DISTRIBUTION_CENTER_PALLET_BALANCE,
   DISTRIBUTION_CENTER_PALLET_PICKUPS,
   DISTRIBUTION_CENTER_PALLETS_TRANSFER_HISTORY,
   STORAGE_PALLET_BALANCE,
   STORAGE_PALLETS_TRANSFER_HISTORY,
   SUPPLIER_CLIENTS,
   SUPPLIER_DISTRIBUTION_CENTER_DETAILS,
   SUPPLIER_ORDERS,
   SUPPLIER_ORDERS_ALL_STATUSES_TAB,
   SUPPLIER_PALLETS,
   SUPPLIER_PALLETS_TRANSFER_HISTORY,
   SUPPLIER_STORAGES,
   SUPPLIER_STORAGES_BY_NAME_PATH,
} from 'constants/routes';
import { useAuth } from 'context/auth/AuthContextProvider';
import { NavItem } from 'storybook';

import styles from './SideBar.module.css';

const displayMobileNavigationPages = [
   CLIENT_ORDERS,
   CLIENT_PALLETS,
   CLIENT_LOST_DESTROYED_PALLETS_BY_CLIENT,
   CLIENT_DAMAGED_PALLETS_BY_CLIENT,
   CLIENT_DESTROYED_PALLETS_BY_SUPPLIER,
];

const SideBar = () => {
   const location = useLocation();
   const { user } = useAuth();

   const renderNavigation = () => {
      switch (user?.mainRole) {
         case 'ROLE_CLIENT':
            return (
               <>
                  <Link to={CLIENT_ORDERS}>
                     <NavItem
                        active={location.pathname.includes(CLIENT_ORDERS)}
                        activeIcon={<Tag stroke="var(--primary-green)" />}
                        icon={<Tag />}
                        text="Zamówienia"
                        className={classNames(styles.navItem, {
                           [styles.navItemActive]: location.pathname.includes(CLIENT_ORDERS),
                        })}
                     />
                  </Link>
                  <Link
                     className={classNames(styles.mobileOnlyBtn, {
                        [styles.disabledLink]: user?.suspension.blocked,
                     })}
                     to={CLIENT_PLACE_ORDER}
                  >
                     <div className={styles.addOrdedBtn}>
                        <PlusWhiteIcon />
                     </div>
                  </Link>
                  <Link to={CLIENT_PALLETS}>
                     <NavItem
                        active={location.pathname === CLIENT_PALLETS}
                        icon={<PalletIcon />}
                        activeIcon={<PalletGreenIcon />}
                        text="Palety – Saldo"
                        className={classNames(styles.navItem, {
                           [styles.navItemActive]: location.pathname === CLIENT_PALLETS,
                        })}
                     />
                  </Link>
                  <Link to={CLIENT_PALLETS_TRANSFER_HISTORY}>
                     <NavItem
                        active={location.pathname === CLIENT_PALLETS_TRANSFER_HISTORY}
                        icon={<History />}
                        text="Historia"
                        className={classNames(styles.navItem, {
                           [styles.navItemActive]:
                              location.pathname === CLIENT_PALLETS_TRANSFER_HISTORY,
                        })}
                     />
                  </Link>
               </>
            );
         case 'ROLE_SYSTEM_ADMIN':
            return (
               <>
                  <Link to={SUPPLIER_ORDERS_ALL_STATUSES_TAB}>
                     <NavItem
                        active={location.pathname.includes(SUPPLIER_ORDERS)}
                        icon={<Tag />}
                        activeIcon={<Tag stroke="var(--primary-green)" />}
                        text="Zamówienia"
                        className={classNames(styles.navItem, {
                           [styles.navItemActive]: location.pathname === SUPPLIER_ORDERS,
                        })}
                     />
                  </Link>
                  <Link to={SUPPLIER_CLIENTS}>
                     <NavItem
                        active={location.pathname.includes(SUPPLIER_CLIENTS)}
                        icon={<UsersIcon />}
                        activeIcon={<UsersGreenIcon />}
                        text="Klienci"
                        className={classNames(styles.navItem, {
                           [styles.navItemActive]: location.pathname === SUPPLIER_CLIENTS,
                        })}
                     />
                  </Link>
                  <Link to={SUPPLIER_PALLETS}>
                     <NavItem
                        active={location.pathname === SUPPLIER_PALLETS}
                        icon={<PalletOutlineIcon />}
                        activeIcon={<PalletOutlineIcon fill="var(--primary-green)" />}
                        text="Palety do wymiany"
                        className={classNames(styles.navItem, {
                           [styles.navItemActive]: location.pathname === SUPPLIER_STORAGES,
                        })}
                     />
                  </Link>
                  <Link to={SUPPLIER_STORAGES_BY_NAME_PATH('internal-storages')}>
                     <NavItem
                        active={
                           location.pathname.includes(SUPPLIER_STORAGES) ||
                           location.pathname.includes(SUPPLIER_DISTRIBUTION_CENTER_DETAILS)
                        }
                        icon={<BuildingWarehouse />}
                        text="Magazyny"
                        className={classNames(styles.navItem, {
                           [styles.navItemActive]:
                              location.pathname === SUPPLIER_STORAGES ||
                              location.pathname.includes(SUPPLIER_DISTRIBUTION_CENTER_DETAILS),
                        })}
                     />
                  </Link>
                  <Link to={SUPPLIER_PALLETS_TRANSFER_HISTORY}>
                     <NavItem
                        active={location.pathname === SUPPLIER_PALLETS_TRANSFER_HISTORY}
                        icon={<History />}
                        text="Historia"
                        className={classNames(styles.navItem, {
                           [styles.navItemActive]:
                              location.pathname === SUPPLIER_PALLETS_TRANSFER_HISTORY,
                        })}
                     />
                  </Link>
               </>
            );
         case 'ROLE_DISTRIBUTION_CENTER':
            return (
               <>
                  <Link to={DISTRIBUTION_CENTER_PALLET_PICKUPS}>
                     <NavItem
                        active={location.pathname === DISTRIBUTION_CENTER_PALLET_PICKUPS}
                        icon={<TruckReturn />}
                        text="Odbiory"
                        className={classNames(styles.navItem, {
                           [styles.navItemActive]:
                              location.pathname === DISTRIBUTION_CENTER_PALLET_PICKUPS,
                        })}
                     />
                  </Link>
                  <Link to={DISTRIBUTION_CENTER_PALLET_BALANCE}>
                     <NavItem
                        active={location.pathname === DISTRIBUTION_CENTER_PALLET_BALANCE}
                        icon={<PalletOutlineIcon />}
                        activeIcon={<PalletOutlineIcon fill="var(--primary-green)" />}
                        text="Saldo"
                        className={classNames(styles.navItem, {
                           [styles.navItemActive]:
                              location.pathname === DISTRIBUTION_CENTER_PALLET_BALANCE,
                        })}
                     />
                  </Link>
                  <Link to={DISTRIBUTION_CENTER_PALLETS_TRANSFER_HISTORY}>
                     <NavItem
                        active={location.pathname === DISTRIBUTION_CENTER_PALLETS_TRANSFER_HISTORY}
                        icon={<History />}
                        text="Historia"
                        className={classNames(styles.navItem, {
                           [styles.navItemActive]:
                              location.pathname === DISTRIBUTION_CENTER_PALLETS_TRANSFER_HISTORY,
                        })}
                     />
                  </Link>
               </>
            );
         case 'ROLE_STORAGE':
            return (
               <>
                  <Link to={STORAGE_PALLET_BALANCE}>
                     <NavItem
                        active={location.pathname === STORAGE_PALLET_BALANCE}
                        icon={<PalletOutlineIcon />}
                        activeIcon={<PalletOutlineIcon fill="var(--primary-green)" />}
                        text="Saldo"
                        className={classNames(styles.navItem, {
                           [styles.navItemActive]: location.pathname === STORAGE_PALLET_BALANCE,
                        })}
                     />
                  </Link>
                  <Link to={STORAGE_PALLETS_TRANSFER_HISTORY}>
                     <NavItem
                        active={location.pathname === STORAGE_PALLETS_TRANSFER_HISTORY}
                        icon={<History />}
                        text="Historia"
                        className={classNames(styles.navItem, {
                           [styles.navItemActive]:
                              location.pathname === STORAGE_PALLETS_TRANSFER_HISTORY,
                        })}
                     />
                  </Link>
               </>
            );
         default:
            return null;
      }
   };

   return (
      <div
         className={classNames(styles.container, {
            [styles.hideMobile]: !displayMobileNavigationPages.includes(location.pathname),
         })}
      >
         <Logo className={styles.logo} />
         <div className={styles.navigation}>{renderNavigation()}</div>
      </div>
   );
};

export default SideBar;
