import { useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';

import { X } from 'tabler-icons-react';

import { orderStatusesLegend } from 'constants/orderStatuses';
import { Banner } from 'storybook';

import StatusLegendItem from './StatusLegendItem/StatusLegendItem';
import styles from './StatusLegend.module.css';

const StatusLegend = () => {
   const [openStatusLegend, setOpenStatusLegend] = useState(false);
   return (
      <OutsideClickHandler onOutsideClick={() => setOpenStatusLegend(false)}>
         <div className={styles.mainContainer}>
            <Banner
               variant="info_gray"
               children={
                  <>
                     <span className={styles.bannerTextDesktopOnly}>Legenda statusów</span>
                     <span className={styles.bannerTextMobileOnly}>Statusy</span>
                  </>
               }
               onClick={() => setOpenStatusLegend(true)}
               className={styles.statusLegendBanner}
            />
            {openStatusLegend && (
               <div className={styles.statusLegendContainer}>
                  <X
                     onClick={() => setOpenStatusLegend(false)}
                     className={styles.mobileOnlyCloseBtn}
                  />
                  <h4>Statusy i ich funkcje:</h4>
                  <ul>
                     {orderStatusesLegend.map(statusItem => (
                        <StatusLegendItem
                           key={statusItem.variant}
                           status={statusItem.status}
                           description={statusItem.description}
                           variant={statusItem.variant}
                        />
                     ))}
                  </ul>
               </div>
            )}
         </div>
      </OutsideClickHandler>
   );
};

export default StatusLegend;
