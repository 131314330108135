import { PageHeader } from 'components/layout';
import { StyledButton } from 'components/shared';

import styles from './PalletsBalancePageHeader.module.css';

type PalletsBalancePageHeaderProps = {
   isSupplierMode?: boolean;
   isButtonsDisabled?: boolean;
   displaySendPalletsModal?: () => void;
   displayReportLostDamagedPalletsModal?: () => void;
};

const PalletsBalancePageHeader = ({
   isSupplierMode,
   isButtonsDisabled,
   displaySendPalletsModal,
   displayReportLostDamagedPalletsModal,
}: PalletsBalancePageHeaderProps) => {
   const buttons = (
      <div className={styles.buttonsContainer}>
         <StyledButton
            onClick={displaySendPalletsModal}
            variant="filled-primary"
            text="Nadaj palety FIFO"
            disabled={isButtonsDisabled}
         />
         <StyledButton
            onClick={displayReportLostDamagedPalletsModal}
            variant="outlined-primary"
            text="Zgłoś zgubione lub uszkodzone palety FIFO"
            disabled={isButtonsDisabled}
         />
      </div>
   );

   return (
      <PageHeader
         headerLeftElement={
            isSupplierMode ? <h1 className={styles.heading}>Szczegóły</h1> : buttons
         }
         headerRightElement={isSupplierMode ? buttons : undefined}
         className={styles.header}
      />
   );
};

export default PalletsBalancePageHeader;
