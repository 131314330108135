import { ChangeEvent } from 'react';

import classNames from 'classnames';
import { Rotate2 } from 'tabler-icons-react';

import { SharedGridTableCell, SharedGridTableRow, StyledTextInput } from 'components/shared';
import { PALLET_TYPES_LABELS_WITH_DIMENSIONS } from 'constants/palletTypes';
import { PalletsPricingPerAddressFormDataItem, PalletType } from 'interfaces';

import styles from './PalletsPricingPerAddressInnerFormTableRow.module.css';

export type PalletsPricingPerAddressInnerFormTableRowProps = {
   isBasePalletsPricingRows: boolean;
   fullAddress: string;
   palletPricing: PalletsPricingPerAddressFormDataItem;
   basePalletPricing: PalletsPricingPerAddressFormDataItem;
   error?: Record<string, boolean>;
   onPalletPricingChange: (
      isBasePalletsPricing: boolean,
      enteredValue: string,
      palletType: PalletType,
      address: string,
   ) => void;
   onPalletPricingBlur: (
      isBasePalletsPricing: boolean,
      enteredValue: string,
      palletType: PalletType,
      address: string,
   ) => void;
};

const PalletsPricingPerAddressInnerFormTableRow = ({
   isBasePalletsPricingRows,
   fullAddress,
   palletPricing,
   basePalletPricing,
   error,
   onPalletPricingChange,
   onPalletPricingBlur,
}: PalletsPricingPerAddressInnerFormTableRowProps) => {
   const { palletType, deliveryPrice } = palletPricing;
   const { deliveryPrice: baseDeliveryPrice } = basePalletPricing;

   const handlePalletsPricingChange = (event: ChangeEvent<HTMLInputElement>) => {
      const enteredValue = event.target.value;
      onPalletPricingChange(isBasePalletsPricingRows, enteredValue, palletType, fullAddress);
   };

   const handlePalletsPricingBlur = (event: ChangeEvent<HTMLInputElement>) => {
      const enteredValue = event.target.value;
      onPalletPricingBlur(isBasePalletsPricingRows, enteredValue, palletType, fullAddress);
   };

   const isBaseDeliveryPriceDifferent = deliveryPrice !== baseDeliveryPrice;

   return (
      <SharedGridTableRow className={styles.row}>
         <SharedGridTableCell className={styles.cell}>
            <h3>{PALLET_TYPES_LABELS_WITH_DIMENSIONS[palletType].label}</h3>
            <p>{PALLET_TYPES_LABELS_WITH_DIMENSIONS[palletType].dimensions}</p>
         </SharedGridTableCell>
         <SharedGridTableCell className={styles.cell}>
            <StyledTextInput
               value={deliveryPrice}
               name="deliveryPrice"
               type="text"
               fullWidth
               rightIcon={isBaseDeliveryPriceDifferent && <Rotate2 />}
               className={classNames({ [styles.changedPriceInput]: isBaseDeliveryPriceDifferent })}
               onChange={handlePalletsPricingChange}
               onBlur={handlePalletsPricingBlur}
               error={error?.deliveryPrice}
            />
         </SharedGridTableCell>
      </SharedGridTableRow>
   );
};

export default PalletsPricingPerAddressInnerFormTableRow;
