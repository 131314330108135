import { ChangeEvent, useState } from 'react';

import { PalletsStackIcon } from 'assets';
import { PageLayout } from 'components/layout';
import { CustomInfiniteScroll, NoItemsNotification } from 'components/shared';
import { usePalletsTransferHistory } from 'hooks';
import { TransferActionOverviewDTO, TransferActionsOverviewFiltersForm } from 'interfaces';
import { initializeDefaultTransferActionsOverviewFiltersForm } from 'utils/dataInitializers';

import PalletsTransferHistoryActionDetailsModal from './PalletsTransferHistoryActionDetailsModal/PalletsTransferHistoryActionDetailsModal';
import PalletsTransferHistoryFiltersModal, {
   PalletsTransferHistoryFiltersModalProps,
} from './PalletsTransferHistoryFiltersModal/PalletsTransferHistoryFiltersModal';
import PalletsTransferHistoryHeader from './PalletsTransferHistoryHeader/PalletsTransferHistoryHeader';
import PalletsTransferHistoryList from './PalletsTransferHistoryList/PalletsTransferHistoryList';
import styles from './PalletsTransferHistory.module.css';

type PalletsTransferHistoryProps = Pick<PalletsTransferHistoryFiltersModalProps, 'mode'>;

const INFINITE_SCROLL_CONTAINER_ID = 'palletsTransferHistoryActionsListInfiniteScrollContainer';

const PalletsTransferHistory = ({ mode }: PalletsTransferHistoryProps) => {
   const {
      transferActionsData,
      transferActionsDataItemsTotalCount,
      isTransferActionsDataFirstFetchLoading,
      isTransferActionsDataError,
      orderIdFilter,
      filtersFormValues,
      additionalFiltersCount,
      isTransferActionsDataInXLSXFormatLoading,
      infiniteScrollContainer,
      bannerData,
      setOrderIdFilter,
      setFiltersFormValues,
      setBannerData,
      handleLoadNextItems,
      handleGetTransferActionsDataInXLSXFormat,
   } = usePalletsTransferHistory();

   const [
      isPalletsTransferHistoryFiltersModalOpened,
      setIsPalletsTransferHistoryFiltersModalOpened,
   ] = useState(false);
   const [selectedTransferActionIdForDetailedView, setSelectedTransferActionIdForDetailedView] =
      useState<string | null>();

   const handlePalletsTransferHistoryModalFiltersSubmit = (
      filtersFormData: TransferActionsOverviewFiltersForm,
   ) => {
      setFiltersFormValues(filtersFormData);
      setIsPalletsTransferHistoryFiltersModalOpened(false);
   };

   const handleOrderIdFilterChange = (event: ChangeEvent<HTMLInputElement>) =>
      setOrderIdFilter(event.target.value);

   const handlePalletsTransferHistoryActionDetailsModalClose = () =>
      setSelectedTransferActionIdForDetailedView(null);

   const handlePalletsTransferHistoryFiltersModalOpen = () =>
      setIsPalletsTransferHistoryFiltersModalOpened(true);

   const handlePalletsTransferHistoryFiltersModalClose = () =>
      setIsPalletsTransferHistoryFiltersModalOpened(false);

   const handleClearFiltersClick = () => {
      setFiltersFormValues(initializeDefaultTransferActionsOverviewFiltersForm());
      setOrderIdFilter('');
   };

   const handleMoreTransferActionItemsDataClick = (
      transferActionOverviewDTO: TransferActionOverviewDTO,
   ) => setSelectedTransferActionIdForDetailedView(transferActionOverviewDTO.id);

   return (
      <>
         {selectedTransferActionIdForDetailedView && (
            <PalletsTransferHistoryActionDetailsModal
               transferActionId={selectedTransferActionIdForDetailedView}
               onClose={handlePalletsTransferHistoryActionDetailsModalClose}
            />
         )}
         <PalletsTransferHistoryFiltersModal
            mode={mode}
            opened={isPalletsTransferHistoryFiltersModalOpened}
            initialFormData={filtersFormValues}
            onSubmit={handlePalletsTransferHistoryModalFiltersSubmit}
            onClose={handlePalletsTransferHistoryFiltersModalClose}
         />
         <PageLayout
            didFetchDataErrorOccur={isTransferActionsDataError}
            bannerData={bannerData}
            closeBanner={() => setBannerData(null)}
            headerElement={
               <PalletsTransferHistoryHeader
                  orderIdFilter={orderIdFilter}
                  additionalFiltersCount={additionalFiltersCount}
                  disabled={isTransferActionsDataError}
                  isTransferActionsDataInXLSXFormatLoading={
                     isTransferActionsDataInXLSXFormatLoading
                  }
                  onOrderIdFilterChange={handleOrderIdFilterChange}
                  onFiltersButtonClick={handlePalletsTransferHistoryFiltersModalOpen}
                  onClearFiltersClick={handleClearFiltersClick}
                  onExportClick={handleGetTransferActionsDataInXLSXFormat}
               />
            }
         >
            <CustomInfiniteScroll
               containerId={INFINITE_SCROLL_CONTAINER_ID}
               containerRef={infiniteScrollContainer}
               next={handleLoadNextItems}
               dataLength={transferActionsData.length}
               hasMore={transferActionsData.length !== transferActionsDataItemsTotalCount}
               isInitialLoading={isTransferActionsDataFirstFetchLoading}
               infiniteScrollClassNames={{
                  innerContainer: styles.infiniteScrollInnerContainer,
                  outerLoader: styles.infiniteScrollOuterLoader,
               }}
               noItemsNotificationElement={
                  <NoItemsNotification
                     icon={<PalletsStackIcon />}
                     description="Brak danych historycznych."
                  />
               }
            >
               {!!transferActionsData.length && (
                  <PalletsTransferHistoryList
                     transferActionData={transferActionsData}
                     onMoreTransferActionItemsDataClick={handleMoreTransferActionItemsDataClick}
                  />
               )}
            </CustomInfiniteScroll>
         </PageLayout>
      </>
   );
};

export default PalletsTransferHistory;
