import { Plus } from 'tabler-icons-react';

import StyledButton from 'components/shared/StyledButton/StyledButton';
import {
   LOST_DAMAGED_PALLETS_BY_CLIENT_DEFAULT_ITEM,
   LOST_DAMAGED_PALLETS_BY_SUPPLIER_DEFAULT_ITEM,
} from 'constants/palletsTableDefaultValues';
import { DamageType, LostDamagedPalletsItem, Option,SelectValue } from 'interfaces';

import LostDamagedPalletsItemCard from './LostDamagedPalletsItemCard/LostDamagedPalletsItemCard';

type LostDamagedPalletsMobileListProps = {
   damageType: DamageType;
   lostDamagedPalletsItems: LostDamagedPalletsItem[];
   lostDamagedPalletsItemsErrors: Record<string, string | boolean>[];
   lostDamagedPalletsItemsTouched: Record<string, boolean>[];
   availablePalletTypes: Option[];
   onAddLostDamagedPalletsItem: (newItem: LostDamagedPalletsItem) => void;
   onRemoveLostDamagedPalletsItem: (itemIndex: number) => void;
   onChangeLostDamagedPalletsItem: (
      itemIndex: number,
      field: string,
      value: string | string[] | SelectValue,
   ) => void;
};

const LostDamagedPalletsMobileList = ({
   damageType,
   lostDamagedPalletsItems,
   lostDamagedPalletsItemsErrors,
   lostDamagedPalletsItemsTouched,
   availablePalletTypes,
   onAddLostDamagedPalletsItem,
   onRemoveLostDamagedPalletsItem,
   onChangeLostDamagedPalletsItem,
}: LostDamagedPalletsMobileListProps) => (
   <div>
      {lostDamagedPalletsItems.map((lostDamagedPalletsItem, index) => (
         <LostDamagedPalletsItemCard
            key={lostDamagedPalletsItem.uuid}
            index={index}
            item={lostDamagedPalletsItems[index]}
            errors={lostDamagedPalletsItemsErrors[index]}
            touched={lostDamagedPalletsItemsTouched[index]}
            availablePalletTypes={availablePalletTypes}
            items={lostDamagedPalletsItems}
            onChangeItem={onChangeLostDamagedPalletsItem}
            onRemoveItem={onRemoveLostDamagedPalletsItem}
         />
      ))}

      <StyledButton
         type="button"
         onClick={() =>
            onAddLostDamagedPalletsItem(
               damageType === 'LOST_DAMAGED_BY_CLIENT'
                  ? LOST_DAMAGED_PALLETS_BY_CLIENT_DEFAULT_ITEM()
                  : LOST_DAMAGED_PALLETS_BY_SUPPLIER_DEFAULT_ITEM(),
            )
         }
         text="Dodaj typ palety"
         icon={<Plus />}
         variant="text"
      />
   </div>
);

export default LostDamagedPalletsMobileList;
