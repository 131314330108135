import qs from 'qs';

import {
   Balance,
   BalancePickUpDTO,
   CanStorageBeDeletedResponse,
   ChargeItemDTO,
   ClientContactDetails,
   ClientUserDTO,
   ClientWithBalanceDTO,
   CommentDTO,
   CommercialNetworkAddress,
   CommercialNetworkDetailsDTO,
   CreateOrUpdateUserDTO,
   CreateSendPalletDTO,
   DistributionCenterDTO,
   DistributionCenterSelectedDayBalanceDTO,
   DocumentDTO,
   EditInternalOrderIdDTO,
   editPalletBalanceRequest,
   LostDamagedPalletsBalance,
   OrderDetailsDTO,
   OrderDTO,
   Pallet,
   PalletPickUpDTO,
   PalletReportDetailsDTO,
   PalletReportDTO,
   ReportStatus,
   ReportType,
   StorageDTO,
   SupplierOrdersPageTab,
   TransferActionDTO,
   TransferActionOverviewDTO,
   TransferActionsOverviewFilters,
} from 'interfaces';

import AxiosInstance from './AxiosInstance';
import {
   ADD_ADDITIONAL_CHARGES,
   ADMIN_ADDITIONAL_ORDERS,
   ADMIN_ORDER,
   ADMIN_ORDERS,
   ADMIN_UNREAD_ORDERS_COUNTS,
   ALL_COMMERCIAL_NETWORKS,
   CAN_COMMERCIAL_NETWORK_BE_DELETED,
   CAN_DISTRIBUTION_CENTER_BE_DELETED,
   CAN_STORAGE_BE_DELETED,
   CHANGE_ORDER_STATUS,
   CHANGE_REPORT_STATUS,
   CHANGE_USER_BLOCKED_STATUS,
   CLIENT_CONTACT_DETAILS_BY_CLIENT_ID,
   CLIENT_PALLET_BALANCES,
   COMMERCIAL_NETWORK,
   COMMERCIAL_NETWORK_DETAILS,
   CREATE_AND_SEND_INVOICE,
   CREATE_CLIENT,
   CURRENT_USER_DETAILS,
   DEACTIVATE_USER,
   DELETE_COMMERCIAL_NETWORK,
   DELETE_DOCUMENT,
   DELETE_ORDER,
   DELETE_STORAGE,
   DISTRIBUTION_CENTER,
   DISTRIBUTION_CENTER_ADMISSION_PALLETS,
   DISTRIBUTION_CENTER_BALANCE,
   DISTRIBUTION_CENTER_BY_ID,
   DISTRIBUTION_CENTER_TRANSFER_PALLETS,
   DISTRIBUTION_CENTER_USER,
   DISTRIBUTION_CENTER_USER_BALANCE,
   DOCUMENT,
   EDIT_DISTRIBUTION_CENTER_BALANCE,
   EDIT_INTERNAL_ORDER_ID,
   EDIT_PALLETS,
   EDIT_SEND_PALLETS_FROM_ORDER,
   GET_ORDERS_DISTRIBUTION_CENTER,
   GET_ORDERS_DISTRIBUTION_CENTER_USER,
   GET_TRANSFER_ACTION_DETAILS_BY_ACTION_ID,
   GET_TRANSFER_HISTORY_OVERVIEW,
   GET_TRANSFER_HISTORY_OVERVIEW_IN_XLSX_FORMAT,
   GUS,
   LOST_DAMAGED_PALLETS_BALANCE,
   LOST_DAMAGED_PALLETS_BALANCE_BY_CLIENT_ID,
   LOST_DAMAGED_PALLETS_BY_CLIENT_ID,
   LOST_DAMAGED_PALLETS_REPORT_DETAILS,
   MERGE_ADDITIONAL_ORDERS,
   ORDER,
   ORDER_DETAILS,
   PALLET,
   PALLET_PICK_UP,
   PALLET_REQUIRED_NUMBER,
   PALLETS_BALANCE_BY_CLIENT_ID,
   PALLETS_SHIPMENT,
   PALLETS_SHIPMENT_ADDRESSES_VERIFICATION,
   PALLETS_SHIPMENT_ADDRESSES_VERIFICATION_BY_CLIENT_ID,
   PALLETS_SHIPMENT_BY_ADMIN,
   RECEIVED_LOST_DAMAGED_PALLETS,
   REMAINING_PALLETS,
   REMAINING_PALLETS_BY_ADMIN,
   RENTED_PALLETS_BALANCE,
   RENTED_PALLETS_BALANCE_BY_CLIENT_ID,
   REPORT_PALLETS,
   REPORT_PALLETS_BY_ADMIN,
   REPORT_PALLETS_FROM_ORDER,
   SEND_PALLETS_FROM_ORDER,
   STORAGE,
   STORAGE_BALANCE,
   SUBMIT_ORDER_FOR_BILING,
   TRANSFER_PALETTES,
   TRANSFER_PALLETS_INSIDE_STORAGE,
   UPDATE_ORDER,
   USED_PALLET_TYPES,
   USER,
   USER_COMMENT,
   USER_DETAILS,
} from './endpoints';
import {
   BalancePalletsDistributionCenter,
   ChangeOrderStatusAction,
   ClientPagination,
   ClientPalletBalancesSearchProps,
   ConfigAdmissionPallets,
   CreateCommercialNetworkRequest,
   CreateDistributionCenter,
   CreateStorageRequest,
   Pagination,
   PalletReportRequest,
   PlaceOrderByAdminRequest,
   PlaceOrderRequest,
   ShipmentAddressValidationRequest,
   StorageSearchProps,
   TransferPalletsDistributionCenter,
   TransferPalletsRequest,
   TransferStoragePalletsRequest,
   UpdateOrderRequest,
} from './requests';
import {
   AdditionalOrderPalletsResponse,
   GetAdminUnreadOrdersCounts,
   GetOrderDocumentsResponse,
   GetOrderListResponse,
   GetRentedPalletsBalanceResponse,
   GetUsedPalletTypesInOrdersResponse,
   GetUserCommercialNetworksResponse,
   PaginationResponse,
   PalletReportsResponse,
   PalletsAmountResponse,
   ReportPalletsFromMultipleOrdersResponse,
   SendPalletsFromMultipleOrdersResponse,
   ShipmentAddressDestinationsResponse,
} from './responses';

class HTTPService {
   private axios = new AxiosInstance();

   // order-controller
   public getOrderDetails = (orderId: string) =>
      this.axios.get<OrderDetailsDTO>(ORDER_DETAILS(orderId));

   public getUserOrderList = (pagination: Pagination, orderId?: string) =>
      this.axios.get<GetOrderListResponse>(ORDER, {
         params: { ...pagination, id: orderId },
         paramsSerializer: params => qs.stringify(params, { arrayFormat: 'repeat' }),
      });

   public placeOrder = (order: PlaceOrderRequest) => this.axios.post<OrderDTO>(ORDER, order);

   public sendPalletsFromOrder = (orderId: string, sendPalletsData: CreateSendPalletDTO[]) =>
      this.axios.post<OrderDetailsDTO>(SEND_PALLETS_FROM_ORDER(orderId), {
         sendPallets: sendPalletsData,
      });

   public sendPalletsFromMultipleOrders = (sendPalletsData: CreateSendPalletDTO[]) =>
      this.axios.post<SendPalletsFromMultipleOrdersResponse>(PALLETS_SHIPMENT, {
         sendPallets: sendPalletsData,
      });

   public editSendPalletsFromOrder = (orderId: string, sendPalletsData: CreateSendPalletDTO[]) =>
      this.axios.patch<OrderDetailsDTO>(EDIT_SEND_PALLETS_FROM_ORDER(orderId), {
         sendPallets: sendPalletsData,
      });

   public reportPalletsFromOrder = (orderId: string, palletReportData: PalletReportRequest) =>
      this.axios.post<PalletReportDTO>(REPORT_PALLETS_FROM_ORDER(orderId), palletReportData);

   public reportPalletsFromMultipleOrders = (palletReportData: PalletReportRequest) =>
      this.axios.post<ReportPalletsFromMultipleOrdersResponse>(REPORT_PALLETS, palletReportData);

   public submitOrderForBilling = (orderId: string) =>
      this.axios.patch<OrderDetailsDTO>(SUBMIT_ORDER_FOR_BILING(orderId));

   public getRemainingPallets = (orderId?: string) =>
      this.axios.get<PalletsAmountResponse>(REMAINING_PALLETS, { params: { orderId } });

   public editInternalOrderId = (orderId: string, editInternalOrderIdDTO: EditInternalOrderIdDTO) =>
      this.axios.patch<OrderDetailsDTO>(EDIT_INTERNAL_ORDER_ID(orderId), editInternalOrderIdDTO);

   // order-admin-controller
   public getAdminOrderList = (
      filter: { orderStatus: SupplierOrdersPageTab; idOrNameSearch?: string },
      pagination: Pagination,
   ) =>
      this.axios.get<GetOrderListResponse>(ADMIN_ORDERS, {
         params: { ...filter, ...pagination },
         paramsSerializer: params => qs.stringify(params, { arrayFormat: 'repeat' }),
      });

   public getAdminUnreadOrdersCounts = () =>
      this.axios.get<GetAdminUnreadOrdersCounts>(ADMIN_UNREAD_ORDERS_COUNTS);

   public placeOrderByAdmin = (orderData: PlaceOrderByAdminRequest) =>
      this.axios.post<OrderDTO>(ADMIN_ORDER, orderData);

   public updateOrder = (orderId: string, updateOrderData: UpdateOrderRequest) =>
      this.axios.put<OrderDTO>(UPDATE_ORDER(orderId), updateOrderData);

   public changeOrderStatus = (orderId: string, status: ChangeOrderStatusAction) =>
      this.axios.patch<OrderDetailsDTO>(CHANGE_ORDER_STATUS(orderId), { status });

   public deleteOrder = (orderId: string) => this.axios.delete(DELETE_ORDER(orderId));

   public addAdditionalCharges = (orderId: string, additionalCharges: ChargeItemDTO[]) =>
      this.axios.patch<OrderDTO>(ADD_ADDITIONAL_CHARGES(orderId), { charges: additionalCharges });

   public getOrdersWithShippedPalletsToDistributionCenter = (
      pagination: Pagination,
      palletType: string,
      palletShipmentDate: string,
      distributionCenterId: string,
   ) =>
      this.axios.get(GET_ORDERS_DISTRIBUTION_CENTER(distributionCenterId), {
         params: { ...pagination, palletType, palletShipmentDate },
      });

   public editPallets = (orderId: string, pallets: Pallet[]) =>
      this.axios.patch<OrderDetailsDTO>(EDIT_PALLETS(orderId), { pallets });

   public getAdditionalOrders = (clientId: string) =>
      this.axios.get<AdditionalOrderPalletsResponse>(ADMIN_ADDITIONAL_ORDERS, {
         params: { userID: clientId },
      });

   public mergeAdditionalOrders = (parentOrderId: string, freeOrderIds: string[]) =>
      this.axios.patch(MERGE_ADDITIONAL_ORDERS(parentOrderId), { freeOrderIds });

   public reportPalletsFromMultipleOrdersByAdmin = (
      clientId: string,
      palletReportData: PalletReportRequest,
   ) =>
      this.axios.post<ReportPalletsFromMultipleOrdersResponse>(
         REPORT_PALLETS_BY_ADMIN(clientId),
         palletReportData,
      );

   public sendPalletsFromMultipleOrdersByAdmin = (
      clientId: string,
      sendPalletsData: CreateSendPalletDTO[],
   ) =>
      this.axios.post<SendPalletsFromMultipleOrdersResponse>(PALLETS_SHIPMENT_BY_ADMIN(clientId), {
         sendPallets: sendPalletsData,
      });

   public getRemainingPalletsByAdmin = (clientId: string, orderId?: string) =>
      this.axios.get<PalletsAmountResponse>(REMAINING_PALLETS_BY_ADMIN(clientId), {
         params: { orderId },
      });

   // user-controller
   public getCurrentUserDetails = () => this.axios.get<ClientUserDTO>(CURRENT_USER_DETAILS);

   // user-admin-controller
   public getUserDetails = (userId: string) => this.axios.get<ClientUserDTO>(USER_DETAILS(userId));

   public getUsers = (pagination: ClientPagination) =>
      this.axios.get<PaginationResponse<ClientUserDTO>>(USER, {
         params: pagination,
         paramsSerializer: params => qs.stringify(params, { arrayFormat: 'repeat' }),
      });

   public deactivateUser = (userId: string) => this.axios.patch(DEACTIVATE_USER(userId));

   public getGUSData = (nip: string) => this.axios.get(GUS, { params: { nip } });

   public changeUserBlockedStatus = (isBlocked: boolean, comment: string, userId: string) =>
      this.axios.patch(CHANGE_USER_BLOCKED_STATUS(userId), { blocked: isBlocked, reason: comment });

   public createClient = (clientData: CreateOrUpdateUserDTO) =>
      this.axios.post<ClientUserDTO>(CREATE_CLIENT, clientData);

   public updateClient = (clientData: CreateOrUpdateUserDTO, id: string) =>
      this.axios.put<ClientUserDTO>(USER_DETAILS(id), clientData);

   public canCommercialNetworkBeDeleted = (commercialNetworkId: string) =>
      this.axios.get(CAN_COMMERCIAL_NETWORK_BE_DELETED(commercialNetworkId));

   // storage-controller
   public getStorageList = (searchParams: Pagination & StorageSearchProps) =>
      this.axios.get<PaginationResponse<StorageDTO>>(STORAGE, {
         params: searchParams,
         paramsSerializer: params => qs.stringify(params, { arrayFormat: 'repeat' }),
      });

   public getStorageBalanceByDate = (id: string, date: string) =>
      this.axios.get<Balance>(STORAGE_BALANCE(id), {
         params: { date },
      });

   public updateStorageBalance = (
      id: string,
      balanceWithDate: { date: string; balance: Balance },
   ) => this.axios.patch<Balance>(STORAGE_BALANCE(id), balanceWithDate);

   public getStorageRemoveState = (id: string) =>
      this.axios.get<CanStorageBeDeletedResponse>(CAN_STORAGE_BE_DELETED(id));

   public removeStorage = (id: string) =>
      this.axios.delete<CanStorageBeDeletedResponse>(DELETE_STORAGE(id));

   public createStorage = (storage: CreateStorageRequest) => this.axios.post(STORAGE, storage);

   // pallet-storage-controller
   public transferPalletsInsideStorage = (
      storageId: string,
      transferStoragePalletsData: TransferStoragePalletsRequest,
   ) =>
      this.axios.post(TRANSFER_PALLETS_INSIDE_STORAGE, transferStoragePalletsData, {
         params: { storageId },
      });

   // document-controller
   public uploadOrderDocument = (id: string, data: FormData) =>
      this.axios.post<DocumentDTO>(DOCUMENT, data, {
         headers: { 'Content-Type': 'multipart/form-data' },
         params: {
            orderId: id,
         },
      });

   public deleteOrderDocument = (id: string) => this.axios.delete(DELETE_DOCUMENT(id));

   public getOrderDocumentsList = (id: string) =>
      this.axios.get<GetOrderDocumentsResponse>(DOCUMENT, { params: { orderId: id } });

   public createAndSendInvoice = (orderId: string) =>
      this.axios.post(CREATE_AND_SEND_INVOICE, { orderId });

   // commercial-network-controller
   public getUserCommercialNetworks = () =>
      this.axios.get<GetUserCommercialNetworksResponse>(COMMERCIAL_NETWORK);

   public getCommercialNetworks = (
      filter?: {
         userId?: string;
         address?: CommercialNetworkAddress;
         nameOrStreetOrCityOrZipcodeSearch?: string;
      },
      pagination?: Pagination,
   ) =>
      this.axios.get<PaginationResponse<CommercialNetworkDetailsDTO>>(ALL_COMMERCIAL_NETWORKS, {
         params: { ...filter, ...pagination },
         paramsSerializer: params => qs.stringify(params, { arrayFormat: 'repeat' }),
      });

   public getCommercialNetworksDetails = (commercialNetworkId: string) =>
      this.axios.get(COMMERCIAL_NETWORK_DETAILS(commercialNetworkId));

   public createCommercialNetwork = (commercialNetworkData: CreateCommercialNetworkRequest) =>
      this.axios.post<CommercialNetworkDetailsDTO>(COMMERCIAL_NETWORK, commercialNetworkData);

   public deleteCommercialNetwork = (commercialNetworkId: string) =>
      this.axios.delete(DELETE_COMMERCIAL_NETWORK(commercialNetworkId));

   public transferPalletsCommercialNetwork = (config: {
      commercialNetworkId: string;
      distributionCenterId: string;
      transferedPalletBalances: TransferPalletsDistributionCenter[];
      date: string;
   }) => {
      const { commercialNetworkId, distributionCenterId, transferedPalletBalances, date } = config;
      return this.axios.patch(
         DISTRIBUTION_CENTER_TRANSFER_PALLETS(commercialNetworkId, distributionCenterId),
         { palletBalances: transferedPalletBalances, date: date },
      );
   };

   public admissionPalletsCommercialNetwork = (config: ConfigAdmissionPallets) => {
      const { commercialNetworkId, distributionCenterId, palletBalances, date, destination } =
         config;
      return this.axios.patch(
         DISTRIBUTION_CENTER_ADMISSION_PALLETS(commercialNetworkId, distributionCenterId),
         { transferPallets: { palletBalances, date }, destination },
      );
   };

   // distribution-center-controller
   public createDistributionCenter = (
      commercialNetworkId: string,
      distributionCenterData: CreateDistributionCenter,
   ) =>
      this.axios.post<DistributionCenterDTO>(
         DISTRIBUTION_CENTER(commercialNetworkId),
         distributionCenterData,
      );

   public getDistributionCenter = (commercialNetworkId: string, distributionCenterId: string) =>
      this.axios.get<DistributionCenterDTO>(
         DISTRIBUTION_CENTER_BY_ID(commercialNetworkId, distributionCenterId),
      );

   public editDistributionCenter = (
      commercialNetworkId: string,
      distributionCenterId: string,
      distributionCenterData: CreateDistributionCenter,
   ) =>
      this.axios.put<DistributionCenterDTO>(
         DISTRIBUTION_CENTER_BY_ID(commercialNetworkId, distributionCenterId),
         distributionCenterData,
      );

   public getBalanceForDistributionCenter = (
      commercialNetworkId: string,
      distributionCenterId: string,
      searchDate: string,
   ) =>
      this.axios.get(DISTRIBUTION_CENTER_BALANCE(commercialNetworkId, distributionCenterId), {
         params: { date: searchDate },
      });

   public deleteDistributionCenter = (commercialNetworkId: string, distributionCenterId: string) =>
      this.axios.delete(DISTRIBUTION_CENTER_BY_ID(commercialNetworkId, distributionCenterId));

   public canDistributionCenterBeDeleted = (
      commercialNetworkId: string,
      distributionCenterId: string,
   ) =>
      this.axios.get(CAN_DISTRIBUTION_CENTER_BE_DELETED(commercialNetworkId, distributionCenterId));

   public editBalanceForDistributionCenter = (
      commercialNetworkId: string,
      distributionCenterId: string,
      balanceId: string,
      updatedPalletsBalance: BalancePalletsDistributionCenter[],
   ) =>
      this.axios.put<DistributionCenterSelectedDayBalanceDTO>(
         EDIT_DISTRIBUTION_CENTER_BALANCE(commercialNetworkId, distributionCenterId, balanceId),
         { updatedPalletBalances: updatedPalletsBalance },
      );

   // pallet-controller
   public getPalletsRequiredNumber = () => this.axios.get(PALLET_REQUIRED_NUMBER);

   public getUserPallets = (receivedDamaged: boolean, palletReportType?: ReportType) =>
      this.axios.get<PalletReportsResponse>(PALLET, {
         params: { receivedDamaged, palletReportType },
      });

   public getCurrentUserRentedPalletsBalance = () =>
      this.axios.get<GetRentedPalletsBalanceResponse>(RENTED_PALLETS_BALANCE);

   public getCurrentUserLostDamagedPalletsBalance = () =>
      this.axios.get<LostDamagedPalletsBalance>(LOST_DAMAGED_PALLETS_BALANCE);

   public getCurrentUserUsedPalletTypesInOrders = () =>
      this.axios.get<GetUsedPalletTypesInOrdersResponse>(USED_PALLET_TYPES);

   public palletsShipmentAddressesVerification = (
      shipmentAddressesData: ShipmentAddressValidationRequest,
   ) =>
      this.axios.post<ShipmentAddressDestinationsResponse>(
         PALLETS_SHIPMENT_ADDRESSES_VERIFICATION,
         shipmentAddressesData,
      );

   // pallet-admin-controller
   public getClientDamagedLostPallets = (
      clientId: string,
      receivedDamaged: boolean,
      palletReportType?: ReportType,
   ) =>
      this.axios.get<PalletReportsResponse>(LOST_DAMAGED_PALLETS_BY_CLIENT_ID(clientId), {
         params: { receivedDamaged, palletReportType },
      });

   public getReceivedLostOrDamagedPallets = (
      orderId?: string,
      userIdOrCompanyNameSearch?: string,
      pagination?: Pagination,
   ) =>
      this.axios.get<PaginationResponse<PalletReportDTO>>(RECEIVED_LOST_DAMAGED_PALLETS, {
         params: { orderId, idOrCompanyNameSearch: userIdOrCompanyNameSearch, ...pagination },
         paramsSerializer: params => qs.stringify(params, { arrayFormat: 'repeat' }),
      });

   public getLostOrDamagedPalletsReportDetails = (reportId: string) =>
      this.axios.get<PalletReportDetailsDTO>(LOST_DAMAGED_PALLETS_REPORT_DETAILS(reportId));

   public transferPallets = (transferPalettes: TransferPalletsRequest) =>
      this.axios.post(TRANSFER_PALETTES, transferPalettes);

   public changeReportStatus = (reportId: string, status: ReportStatus) =>
      this.axios.patch(CHANGE_REPORT_STATUS(reportId), { status });

   public clientPalletsShipmentAddressesVerification = (
      clientId: string,
      shipmentAddressesData: ShipmentAddressValidationRequest,
   ) =>
      this.axios.post<ShipmentAddressDestinationsResponse>(
         PALLETS_SHIPMENT_ADDRESSES_VERIFICATION_BY_CLIENT_ID(clientId),
         shipmentAddressesData,
      );

   // comment-controller
   public getUserComments = (userId: string, pagination: Pagination) =>
      this.axios.get<PaginationResponse<CommentDTO>>(USER_COMMENT(userId), {
         params: pagination,
         paramsSerializer: params => qs.stringify(params, { arrayFormat: 'repeat' }),
      });

   public postUserComment = (userId: string, comment: string) =>
      this.axios.post<CommentDTO>(USER_COMMENT(userId), { comment });

   public editUserComment = (userId: string, commentId: string, comment: string) =>
      this.axios.patch<CommentDTO>(USER_COMMENT(userId) + `/${commentId}`, { comment });

   public deleteUserComment = (userId: string, commentId: string) =>
      this.axios.delete(USER_COMMENT(userId) + `/${commentId}`);

   public getUserPickUps = (pagination: Pagination) =>
      this.axios.get<PaginationResponse<PalletPickUpDTO>>(PALLET_PICK_UP, {
         params: pagination,
         paramsSerializer: params => qs.stringify(params, { arrayFormat: 'repeat' }),
      });

   public createPalletPickUp = (palletBalances: BalancePickUpDTO[], dateTime: string) =>
      this.axios.post<PalletPickUpDTO>(PALLET_PICK_UP, { palletBalances, pickUpTime: dateTime });

   //client-balance-supplier-controller
   public getClientPalletBalances = (searchParams: Pagination & ClientPalletBalancesSearchProps) =>
      this.axios.get<PaginationResponse<ClientWithBalanceDTO>>(CLIENT_PALLET_BALANCES, {
         params: searchParams,
         paramsSerializer: params => qs.stringify(params, { arrayFormat: 'repeat' }),
      });

   public getClientRentedPalletsBalance = (clientId: string) =>
      this.axios.get<GetRentedPalletsBalanceResponse>(
         RENTED_PALLETS_BALANCE_BY_CLIENT_ID(clientId),
      );

   public getClientLostDamagedPalletsBalance = (clientId: string) =>
      this.axios.get<LostDamagedPalletsBalance>(
         LOST_DAMAGED_PALLETS_BALANCE_BY_CLIENT_ID(clientId),
      );

   public editClientBalance = (userId: string, palletBalances: editPalletBalanceRequest[]) =>
      this.axios.put<PalletPickUpDTO>(PALLETS_BALANCE_BY_CLIENT_ID(userId), { palletBalances });

   //user-distribution-center-controller
   public getClientContactDetails = (clientId: string) =>
      this.axios.get<ClientContactDetails>(CLIENT_CONTACT_DETAILS_BY_CLIENT_ID(clientId));

   // distribution-center-user-controller
   public getUserDistributionCenterBalance = (date: string) =>
      this.axios.get<DistributionCenterSelectedDayBalanceDTO>(DISTRIBUTION_CENTER_USER_BALANCE, {
         params: { date },
      });

   public getUserDistributionCenterInformation = () => this.axios.get(DISTRIBUTION_CENTER_USER);

   public getOrdersWithShippedPalletsToDistributionCenterUser = (
      pagination: Pagination,
      palletType: string,
      palletShipmentDate: string,
   ) =>
      this.axios.get(GET_ORDERS_DISTRIBUTION_CENTER_USER, {
         params: { ...pagination, palletType, palletShipmentDate },
      });

   //transfer-history-controller
   public getTransferHistoryOverview = (
      pagination: Pagination,
      filters?: TransferActionsOverviewFilters,
   ) =>
      this.axios.get<PaginationResponse<TransferActionOverviewDTO>>(GET_TRANSFER_HISTORY_OVERVIEW, {
         params: { ...pagination, ...filters },
         paramsSerializer: params => qs.stringify(params, { arrayFormat: 'repeat' }),
      });

   public getTransferActionDetails = (transferActionId: string) =>
      this.axios.get<TransferActionDTO>(GET_TRANSFER_ACTION_DETAILS_BY_ACTION_ID(transferActionId));

   public getTransferHistoryOverviewInXLSXFormat = (filters?: TransferActionsOverviewFilters) =>
      this.axios.get<string>(GET_TRANSFER_HISTORY_OVERVIEW_IN_XLSX_FORMAT, {
         params: filters,
         responseType: 'arraybuffer',
      });
}

export default new HTTPService();
