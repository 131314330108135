import { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useDebouncedValue } from '@mantine/hooks';
import classNames from 'classnames';
import { DateTime } from 'luxon';
import { Search } from 'tabler-icons-react';
import { Ban } from 'tabler-icons-react';

import { PalletsStackIcon } from 'assets';
import { StatusLegend } from 'components/Orders/Dashboard';
import { CustomInfiniteScroll, NoItemsNotificationWithLink } from 'components/shared';
import StyledButton from 'components/shared/StyledButton/StyledButton';
import StyledTextInput from 'components/shared/StyledTextInput/StyledTextInput';
import { OrderTable } from 'components/SupplierPanel';
import { DEFAULT_ERROR_DESCRIPTIONS } from 'constants/errorDescriptions';
import { PAGINATION_SIZE } from 'constants/pagination';
import { CLIENT_PLACE_ORDER } from 'constants/routes';
import { useAuth } from 'context/auth/AuthContextProvider';
import WithAuth from 'hoc/withAuth';
import { BannerData, ClientOrdersTableItem } from 'interfaces/commonInterfacesAndTypes';
import { HTTPService } from 'service';
import { orderDTOToClientOrdersTableItemMapper } from 'service/http/mappers';
import { Banner } from 'storybook';
import { errorHandler } from 'utils/errorHandler';

import styles from './OrdersPage.module.css';

const OrdersPage = () => {
   const navigate = useNavigate();
   const { user } = useAuth();
   const [orders, setOrders] = useState<ClientOrdersTableItem[]>([]);
   const [ordersTotalCount, setOrdersTotalCount] = useState(0);
   const [isPendingOrdersShipmentAlertVisible, setIsPendingOrdersShipmentAlertVisible] = useState(false);
   const [page, setPage] = useState(0);
   const [orderIdFilter, setOrderIdFilter] = useState<string>();
   const [debouncedOrderIdFilter] = useDebouncedValue(orderIdFilter, 200);
   const [isOrdersFirstFetchLoading, setIsOrdersFirstFetchLoading] = useState(false);
   const [bannerData, setBannerData] = useState<BannerData | null>(null);
   const tableContainer = useRef<HTMLDivElement>(null);

   const currentMonth = DateTime.now().setLocale('pl-PL').monthLong;

   const fetchOrders = useCallback(
      (pageNumber: number, firstFetch?: boolean) => {
         firstFetch && setIsOrdersFirstFetchLoading(true);
         HTTPService.getUserOrderList(
            { page: pageNumber, size: PAGINATION_SIZE },
            debouncedOrderIdFilter,
         )
            .then(({ data: orderListData }) => {
               if (firstFetch) {
                  setOrdersTotalCount(orderListData.totalElements);
                  setIsPendingOrdersShipmentAlertVisible(orderListData.ordersAlert);
               }
               const orderListItems = orderListData.orders.map(
                  orderDTOToClientOrdersTableItemMapper,
               );
               firstFetch
                  ? setOrders(orderListItems)
                  : setOrders(prevOrders => prevOrders.concat(orderListItems));
            })
            .catch(handleFetchOrdersError)
            .finally(() => firstFetch && setIsOrdersFirstFetchLoading(false));
      },
      [debouncedOrderIdFilter],
   );

   const handleFetchOrdersError = (error: unknown) =>
      errorHandler(error, () =>
         setBannerData({
            variant: 'error',
            description: DEFAULT_ERROR_DESCRIPTIONS.FETCH_DATA,
         }),
      );

   useEffect(() => {
      fetchOrders(0, true);
      setPage(1);
      tableContainer.current && (tableContainer.current.scrollTop = 0);
   }, [fetchOrders]);

   const handleLoadNextOrders = () => {
      fetchOrders(page);
      setPage(prevPage => prevPage + 1);
   };

   const isClientCanPlaceOrder = user?.suspension.blocked === false;
   const withAlertBanners = isPendingOrdersShipmentAlertVisible || user?.suspension.blocked || bannerData;

   return (
      <div className={styles.mainContainer}>
         <div className={styles.headerContainer}>
            <div
               className={classNames(styles.bannersContainer, {
                  [styles.withAlertBanners]: withAlertBanners,
               })}
            >
               {user?.suspension.blocked && (
                  <Banner
                     children={
                        <>
                           <p>Możliwość składania zamówień została zablokowana.</p>
                           <p>{user?.suspension.reason}</p>
                        </>
                     }
                     variant="error"
                     icon={<Ban />}
                     fullWidth
                     className={classNames(styles.banner, styles.suspensionInfoBanner)}
                  />
               )}
               {isPendingOrdersShipmentAlertVisible && (
                  <Banner
                     children={`Pamiętaj o uzupełnieniu NADAŃ PALET dla zamówień z miesiąca: ${currentMonth}!`}
                     variant="error"
                     fullWidth
                     className={styles.banner}
                  />
               )}
               {bannerData && (
                  <Banner
                     variant={bannerData.variant}
                     title={bannerData.title}
                     children={bannerData.description}
                     className={styles.banner}
                     onClose={() => setBannerData(null)}
                     withCloseIcon
                     fullWidth
                  />
               )}
               <Banner
                  fullWidth
                  variant="info"
                  children="Przesuń stronę na boki, aby zobaczyć wszystkie dane w tabeli."
                  className={classNames(styles.banner, styles.mobileInfoBanner)}
               />
            </div>
            <div className={styles.header}>
               <div>
                  <StyledTextInput
                     type="text"
                     onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        setOrderIdFilter(event.target.value)
                     }
                     value={orderIdFilter}
                     placeholder="wpisz ID zamówienia"
                     leftIcon={<Search />}
                     className={styles.searchInput}
                  />
                  <StyledButton
                     variant="filled-primary"
                     text="Złóż zamówienie"
                     onClick={() => navigate(CLIENT_PLACE_ORDER)}
                     disabled={!isClientCanPlaceOrder}
                     className={styles.searchBtn}
                  />
               </div>
               <StatusLegend />
            </div>
         </div>
         <CustomInfiniteScroll
            containerId="orderTableInfiniteScrollContainer"
            containerRef={tableContainer}
            next={handleLoadNextOrders}
            dataLength={orders.length}
            hasMore={orders.length !== ordersTotalCount}
            isInitialLoading={isOrdersFirstFetchLoading}
            noItemsNotificationElement={
               <NoItemsNotificationWithLink
                  icon={<PalletsStackIcon />}
                  desctiption="Nie masz jeszcze żadnych zamówień."
                  linkText="Złóż zamówienie"
                  redirectTo={CLIENT_PLACE_ORDER}
                  isLinkDisabled={!isClientCanPlaceOrder}
               />
            }
         >
            <OrderTable
               mode="CLIENT_TABLE"
               orders={orders}
               className={classNames({
                  [styles.tableHideMobile]: !orders.length,
               })}
            />
         </CustomInfiniteScroll>
      </div>
   );
};

export default WithAuth(OrdersPage, 'ROLE_CLIENT');
