import { StyledBadge } from 'components/shared';
import { OrderStatus } from 'interfaces/commonInterfacesAndTypes';

import styles from './StatusLegendItem.module.css';

type StatusLegendItemProps = {
   status: string;
   description: string;
   variant: OrderStatus;
};

const StatusLegendItem = ({ status, description, variant }: StatusLegendItemProps) => (
   <li className={styles.mainContainer}>
      <div className={styles.badgeContainer}>
         <StyledBadge text={status} variant={variant} />
      </div>
      <p>{description}</p>
   </li>
);

export default StatusLegendItem;
