import classNames from 'classnames';
import { DateTime } from 'luxon';
import { CalendarTime } from 'tabler-icons-react';
import { AlertTriangle } from 'tabler-icons-react';

import { ToolTip } from 'components/shared';
import { DATE_FORMAT } from 'constants/dateFormats';
import { OrderStatus } from 'interfaces';
import {
   areLastThreeDaysInActualMonth,
   isBeforeToPayStatus,
   isOrderWithLeftPalletsToSendInActualMonth,
   isOrderWithRetentionDateExpiredAndWithPalletsToSend,
} from 'utils/functions';

import styles from './RetentionDateInfo.module.css';

type RetentionDateInfoProps = {
   retentionDate: string;
   orderStatus: OrderStatus;
};

const RetentionDateInfo = ({ retentionDate, orderStatus }: RetentionDateInfoProps) => {
   const isBeforeToPayStatusValue = isBeforeToPayStatus(orderStatus);
   const areLastThreeDaysInMonth = areLastThreeDaysInActualMonth();
   const isVisibleSendPalletsWarning = isOrderWithLeftPalletsToSendInActualMonth(
      retentionDate,
      areLastThreeDaysInMonth,
      isBeforeToPayStatusValue,
   );
   const isVisibleRetentionDateExpiredWarning = isOrderWithRetentionDateExpiredAndWithPalletsToSend(
      DateTime.fromFormat(retentionDate, DATE_FORMAT),
      isBeforeToPayStatusValue,
   );

   return (
      <span
         className={classNames(styles.container, {
            [styles.warning]: isVisibleSendPalletsWarning || isVisibleRetentionDateExpiredWarning,
         })}
      >
         {retentionDate}
         {isVisibleSendPalletsWarning && (
            <ToolTip
               content="Uzupełnij nadania palet"
               variant="right"
               className={styles.tooltip}
               visible
            >
               <AlertTriangle color={'var(--primary-red)'} />
            </ToolTip>
         )}
         {isVisibleRetentionDateExpiredWarning && (
            <ToolTip
               content="Data przetrzymania minęła"
               variant="right"
               className={styles.tooltip}
               visible
            >
               <CalendarTime color={'var(--primary-red)'} />
            </ToolTip>
         )}
      </span>
   );
};

export default RetentionDateInfo;
