import Layout from 'components/layout/MainLayout/Layout';
import {
   SUPPLIER_ADD_CLIENT,
   SUPPLIER_CLIENT_PALLETS_BALANCE_DETAILS_PATH,
   SUPPLIER_CLIENTS,
   SUPPLIER_DAMAGED_BY_CLIENT_PALLETS_PATH,
   SUPPLIER_DAMAGED_DESTROYED_BY_SUPPLIER_PALLETS_PATH,
   SUPPLIER_DISTRIBUTION_CENTER_DETAILS_PATH,
   SUPPLIER_EDIT_CLIENT,
   SUPPLIER_LOST_DESTROYED_BY_CLIENT_PALLETS_PATH,
   SUPPLIER_ORDERS,
   SUPPLIER_ORDERS_DETAILS,
   SUPPLIER_PALLETS,
   SUPPLIER_PALLETS_DETAILS,
   SUPPLIER_PALLETS_TRANSFER_HISTORY,
   SUPPLIER_PLACE_ORDER,
   SUPPLIER_STORAGES_BY_NAME_PATH,
} from 'constants/routes';
import {
   AddClientPage,
   ClientDetailsPage,
   ClientPalletBalanceDetailsPage,
   ClientsPage,
   DamagedPalletsByClientPage,
   DamagedPalletsBySupplierPage,
   EditClientPage,
   LostDestroyedPalletsByClientPage,
   OrderDetailsPage,
   OrdersPage,
   PalletsDetailsPage,
   PalletsPage,
   PalletsTransferHistoryPage,
   PlaceOrderPage,
   StoragesPage,
} from 'pages/SupplierPanel';
import DistributionCenterDetails from 'pages/SupplierPanel/StoragesPage/CommercialNetworkPage/DistributionCenterDetailsPage/DistributionCenterDetailsPage';

export const supplierRoutes = {
   element: <Layout />,
   children: [
      { path: SUPPLIER_CLIENTS, element: <ClientsPage /> },
      { path: SUPPLIER_CLIENTS + '/:id', element: <ClientDetailsPage /> },
      { path: SUPPLIER_ADD_CLIENT, element: <AddClientPage /> },
      { path: SUPPLIER_EDIT_CLIENT + '/:id', element: <EditClientPage /> },
      { path: SUPPLIER_ORDERS, element: <OrdersPage /> },
      { path: SUPPLIER_PLACE_ORDER, element: <PlaceOrderPage /> },
      { path: SUPPLIER_ORDERS_DETAILS(':id'), element: <OrderDetailsPage /> },
      { path: SUPPLIER_STORAGES_BY_NAME_PATH(':storageName'), element: <StoragesPage /> },
      { path: SUPPLIER_PALLETS, element: <PalletsPage /> },
      { path: SUPPLIER_PALLETS_DETAILS(':id'), element: <PalletsDetailsPage /> },
      {
         path: SUPPLIER_DISTRIBUTION_CENTER_DETAILS_PATH(':id'),
         element: <DistributionCenterDetails />,
      },
      {
         path: SUPPLIER_CLIENT_PALLETS_BALANCE_DETAILS_PATH(':id'),
         element: <ClientPalletBalanceDetailsPage />,
      },
      {
         path: SUPPLIER_LOST_DESTROYED_BY_CLIENT_PALLETS_PATH(':id'),
         element: <LostDestroyedPalletsByClientPage />,
      },
      {
         path: SUPPLIER_DAMAGED_DESTROYED_BY_SUPPLIER_PALLETS_PATH(':id'),
         element: <DamagedPalletsBySupplierPage />,
      },
      {
         path: SUPPLIER_DAMAGED_BY_CLIENT_PALLETS_PATH(':id'),
         element: <DamagedPalletsByClientPage />,
      },
      { path: SUPPLIER_PALLETS_TRANSFER_HISTORY, element: <PalletsTransferHistoryPage /> },
   ],
};
