import classNames from 'classnames';

import { Badge, BadgeProps } from 'storybook';

import { OrderStatus, ReportStatus } from '../../../interfaces/commonInterfacesAndTypes';

import styles from './StyledBadge.module.css';

interface StyledBadgeProps extends BadgeProps {
   variant: OrderStatus | ReportStatus | 'NEW' | 'FREE' | 'POSITIVE' | 'NEGATIVE';
}

const WAITING_STATES = ['WAITING', 'REPORTED'];
const COMPLETED_STATES = ['ENDED', 'REVIEWED'];

const StyledBadge = ({ variant, ...props }: StyledBadgeProps) => (
   <Badge
      {...props}
      className={classNames(
         styles.badge,
         {
            [styles.waiting]: WAITING_STATES.includes(variant),
            [styles.inProgress]: variant === 'IN_PROGRESS',
            [styles.delivered]: variant === 'DELIVERED',
            [styles.completed]: COMPLETED_STATES.includes(variant),
            [styles.canceled]: variant === 'CANCELED',
            [styles.duringBilling]: variant === 'DURING_BILLING',
            [styles.toPay]: variant === 'TO_PAY',
            [styles.new]: variant === 'NEW',
            [styles.free]: variant === 'FREE',
            [styles.positive]: variant === 'POSITIVE',
            [styles.negative]: variant === 'NEGATIVE',
         },
         props.className,
      )}
   />
);

export default StyledBadge;
