import { ReactNode } from 'react';

import {
   BillingIcon,
   DeliverIcon,
   EditIcon,
   MoveIcon,
   OrderIcon,
   ReportIcon,
   SendIcon,
} from 'assets';
import { TransferActionType } from 'interfaces';

export const TRANSFER_ACTION_TYPE_LABELS_AND_ICONS: Record<
   TransferActionType,
   { icon: ReactNode; label: string }
> = {
   EDIT_BALANCE: { icon: <EditIcon />, label: 'Edycja salda' },
   EDIT_ORDER_PALLETS: { icon: <EditIcon />, label: 'Edycja zamówienia' },
   TRANSFER_PALLETS: { icon: <MoveIcon />, label: 'Przerzucenie' },
   TRANSFER_PALLETS_FOR_COLLECTION: { icon: <MoveIcon />, label: 'Przerzucenie do odbioru' },
   SEND_PALLETS: { icon: <SendIcon />, label: 'Nadanie' },
   DELIVER_PALLETS: { icon: <DeliverIcon />, label: 'Wysyłka' },
   ACCEPT_INTO_DESTINATION: { icon: <MoveIcon />, label: 'Przyjęcie na magazyn' },
   CANCEL_REPORT: {
      icon: <ReportIcon />,
      label: 'Anulowanie zgłoszenia zgubionych, zniszczonych lub uszkodzone w dostawie palet',
   },
   ACCEPT_ORDER: { icon: <OrderIcon />, label: 'Akceptacja zamówienia' },
   ADD_ADDITIONAL_CHARGES: { icon: <BillingIcon />, label: 'Dodanie dodatkowych opłat' },
   REPORT_PALLETS: {
      icon: <ReportIcon />,
      label: 'Zgłoszenie zgubionych, zniszczonych lub uszkodzone w dostawie palet',
   },
   CANCEL_ORDER: { icon: <OrderIcon />, label: 'Anulowanie zamówienia' },
   CREATE_ORDER: { icon: <OrderIcon />, label: 'Dodanie zamówienia' },
   SUBMIT_FOR_BILLING: { icon: <BillingIcon />, label: 'Przesłanie do rozliczeń' },
   UPDATE_ORDER: { icon: <OrderIcon />, label: 'Edycja zamówienia' },
};
