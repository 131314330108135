import React, { useCallback, useEffect, useMemo, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import { Loader } from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import classNames from 'classnames';
import { Ban, Pencil, Plus, Search, UserOff } from 'tabler-icons-react';

import { BlockedIcon, EyeIcon, UserOffIcon } from 'assets';
import {
   ActionsDropdown,
   NoItemsNotificationWithLink,
   StyledButton,
   StyledTable,
   StyledTextInput,
} from 'components/shared';
import {
   BlockClientModal,
   CannotDeactivateClientModal,
   DeactivateClientModal,
   UnblockClientModal,
} from 'components/SupplierPanel';
import { DEBOUNCE_DELAY } from 'constants/debounce';
import { DEFAULT_ERROR_DESCRIPTIONS } from 'constants/errorDescriptions';
import { PAGINATION_SIZE } from 'constants/pagination';
import {
   SUPPLIER_ADD_CLIENT,
   SUPPLIER_CLIENT_DETAILS_PATH,
   SUPPLIER_EDIT_CLIENT_PATH,
   SUPPLIER_PLACE_ORDER,
} from 'constants/routes';
import { clientsTableHeaders } from 'constants/tableHeaders';
import WithAuth from 'hoc/withAuth';
import { usePagination } from 'hooks/usePagination';
import { BannerData, ClientUserDTO } from 'interfaces';
import { HTTPService } from 'service';
import { Banner, Tab, TableCell, TableRow } from 'storybook';
import { errorHandler } from 'utils/errorHandler';

import styles from './ClientsPage.module.css';

const BANNERS: { [key: string]: BannerData } = {
   SUCCESS_ADD_CLIENT: {
      variant: 'success',
      description: 'Klient został dodany.',
   },
   SUCCESS_EDIT_CLIENT: {
      variant: 'success',
      description: 'Dane klienta zostały zmienione',
   },
};

const DEFAULT_DEACTIVATE_MODAL_DATA = {
   open: false,
   clientId: '',
};

const ClientsPage = () => {
   const [deactivateModal, setDeactivateModal] = useState<{ open: boolean; clientId: string }>(
      DEFAULT_DEACTIVATE_MODAL_DATA,
   );
   const [cannotDeactivateClientModal, setCannotDeactivateClientModal] = useState(false);
   const [deactivatedUserInfo, setDeactivatedUserInfo] = useState(false);
   const [loading, setLoading] = useState(false);
   const [searchValue, setSearchValue] = useState('');
   const [debouncedSearchValue] = useDebouncedValue(searchValue, DEBOUNCE_DELAY);
   const [activeTab, setActiveTab] = useState<'ALL' | 'BLOCKED'>('ALL');
   const [clients, setClients] = useState<ClientUserDTO[]>([]);
   const [itemsTotalCount, setItemsTotalCount] = useState(0);
   const [isChangeClientStatusModalOpen, setIsChangeClientStatusModalOpen] = useState<{
      actionType: 'BLOCK' | 'UNBLOCK';
      userId: string;
   } | null>(null);
   const [successBlockClientBannerOpen, setSuccessBlockClientBannerOpen] = useState(false);
   const [successUnblockClientBannerOpen, setSuccessUnblockClientBannerOpen] = useState(false);
   const [banner, setBanner] = useState<BannerData | null>(null);
   const location = useLocation();
   const navigate = useNavigate();

   useEffect(() => {
      const { successCreateClient } = (location.state || {}) as { successCreateClient: boolean };
      const { successEditClient } = (location.state || {}) as { successEditClient: boolean };
      successCreateClient && setBanner(BANNERS.SUCCESS_ADD_CLIENT);
      successEditClient && setBanner(BANNERS.SUCCESS_EDIT_CLIENT);
      window.history.replaceState({}, '');
   }, [location.state]);

   const fetchClients = useCallback(
      (currentPage: number, filter?: { search?: string; blocked?: boolean }) => {
         currentPage === 0 && setLoading(true);
         HTTPService.getUsers({
            page: currentPage,
            size: PAGINATION_SIZE,
            blocked: filter?.blocked || null,
            search: filter?.search,
            sort: ['active,DESC', 'suspension.blocked,ASC', 'companyName,ASC'],
         })
            .then(res => {
               if (currentPage === 0) {
                  setClients(res.data.content);
                  setItemsTotalCount(res.data.totalElements);
               } else {
                  setClients(prevValues => [...prevValues, ...res.data.content]);
               }
            })
            .catch(handleFetchClientsError)
            .finally(() => setLoading(false));
      },
      [],
   );

   const handleFetchClientsError = (error: unknown) =>
      errorHandler(error, () =>
         setBanner({
            variant: 'error',
            description: DEFAULT_ERROR_DESCRIPTIONS.FETCH_DATA,
         }),
      );

   const filter = useMemo(
      () => ({ blocked: activeTab === 'BLOCKED', search: debouncedSearchValue }),
      [activeTab, debouncedSearchValue],
   );

   const { handleLoadNextItems } = usePagination({
      fetchFunction: fetchClients,
      filter,
   });

   const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) =>
      setSearchValue(e.target.value);

   const handleTabClick = (tab: 'ALL' | 'BLOCKED') => setActiveTab(tab);

   const handleSuccessBlockClient = (clientId: string) => {
      const updatedClients = clients.map(client => {
         if (client.id === clientId) {
            return {
               ...client,
               suspension: { blocked: true, reason: '' },
            };
         }
         return client;
      });
      setClients(updatedClients);
      setSuccessBlockClientBannerOpen(true);
   };

   const handleSuccessUnblockClient = (clientId: string) => {
      const updatedClients = clients.map(client => {
         if (client.id === clientId) {
            return {
               ...client,
               suspension: { blocked: false, reason: '' },
            };
         }
         return client;
      });
      setClients(updatedClients);
      setSuccessUnblockClientBannerOpen(true);
   };

   const handleCloseChangeClientStatusModal = useCallback(
      () => setIsChangeClientStatusModalOpen(null),
      [],
   );

   const handleCloseDeactivateClientModal = useCallback(
      () => setDeactivateModal(DEFAULT_DEACTIVATE_MODAL_DATA),
      [],
   );

   return (
      <>
         <BlockClientModal
            opened={isChangeClientStatusModalOpen?.actionType === 'BLOCK'}
            onSuccessBlockClient={handleSuccessBlockClient}
            onClose={handleCloseChangeClientStatusModal}
            selectedUserId={isChangeClientStatusModalOpen?.userId || ''}
         />
         <UnblockClientModal
            opened={isChangeClientStatusModalOpen?.actionType === 'UNBLOCK'}
            onSuccessBlockClient={handleSuccessUnblockClient}
            onClose={handleCloseChangeClientStatusModal}
            selectedUserId={isChangeClientStatusModalOpen?.userId || ''}
         />
         <DeactivateClientModal
            onClose={handleCloseDeactivateClientModal}
            clientId={deactivateModal.clientId}
            opened={deactivateModal?.open}
            setDeactivatedUserInfo={setDeactivatedUserInfo}
            setCannotDeactivateClientModal={setCannotDeactivateClientModal}
         />
         <CannotDeactivateClientModal
            opened={cannotDeactivateClientModal}
            onClose={() => setCannotDeactivateClientModal(false)}
         />
         <div className={styles.contentContainer}>
            {banner && (
               <Banner
                  variant={banner.variant}
                  title={banner.title}
                  children={banner.description}
                  onClose={() => setBanner(null)}
                  withCloseIcon
                  fullWidth
                  style={{ marginBottom: 24 }}
               />
            )}
            {deactivatedUserInfo && (
               <Banner
                  style={{ marginBottom: 36 }}
                  icon={<UserOff />}
                  fullWidth
                  withCloseIcon
                  onClose={() => setDeactivatedUserInfo(false)}
                  variant="error"
               >
                  Klient został dezaktywowany i jest dostępny na samym dole listy.
               </Banner>
            )}
            {successBlockClientBannerOpen && (
               <Banner
                  variant="error"
                  withCloseIcon
                  onClose={() => setSuccessBlockClientBannerOpen(false)}
                  fullWidth
                  icon={<Ban size={20} strokeWidth={2} color={'var(--primary-red)'} />}
                  style={{ marginBottom: 24 }}
               >
                  Klient został zablokowany. Od teraz nie może składać zamówień.
               </Banner>
            )}
            {successUnblockClientBannerOpen && (
               <Banner
                  variant="success"
                  withCloseIcon
                  onClose={() => setSuccessUnblockClientBannerOpen(false)}
                  fullWidth
                  style={{ marginBottom: 24 }}
               >
                  Klient został odblokowany. Od teraz ponownie może składać zamówienia.
               </Banner>
            )}
            <div className={styles.searchContainer}>
               <StyledTextInput
                  leftIcon={<Search size={20} color="var(--primary-text)" />}
                  type="text"
                  placeholder="np. Nazwa firmy, NIP"
                  value={searchValue}
                  onChange={handleSearchChange}
               />
               <Link to={SUPPLIER_ADD_CLIENT}>
                  <StyledButton variant="filled-primary" text="Dodaj klienta" />
               </Link>
            </div>
            <div className={styles.tabsContainer}>
               <Tab
                  onClick={() => handleTabClick('ALL')}
                  text="Wszyscy"
                  active={activeTab === 'ALL'}
               />
               <Tab
                  onClick={() => handleTabClick('BLOCKED')}
                  text="Zablokowani"
                  active={activeTab === 'BLOCKED'}
               />
            </div>
            <div className={styles.tableLoaderContainer}>
               {loading && (
                  <div className={classNames(styles.loader, styles.mainTableLoader)}>
                     <Loader color={'var(--primary-green)'} />
                  </div>
               )}
               <div id="tableContainer" className={styles.tableContainer}>
                  <InfiniteScroll
                     scrollableTarget="tableContainer"
                     dataLength={clients.length}
                     next={handleLoadNextItems}
                     hasMore={clients.length !== itemsTotalCount}
                     loader={
                        <div className={styles.loader}>
                           <Loader color={'var(--primary-green)'} />
                        </div>
                     }
                  >
                     <StyledTable
                        className={styles.clientsTable}
                        columnHeaders={clientsTableHeaders}
                     >
                        {clients.map(client => (
                           <TableRow
                              className={classNames({
                                 [styles.deactivatedUser]: !client.active,
                              })}
                              key={client.id}
                           >
                              <TableCell>{client.suspension.blocked && <BlockedIcon />}</TableCell>
                              <TableCell>{client.companyName}</TableCell>
                              <TableCell>{client.nip}</TableCell>
                              <TableCell>{client.email}</TableCell>
                              <TableCell>{client.phoneNumber}</TableCell>
                              <TableCell align="right">
                                 <div className={styles.actionsContainer}>
                                    <Link to={SUPPLIER_CLIENT_DETAILS_PATH(client.id)}>
                                       <StyledButton
                                          icon={<EyeIcon />}
                                          className={styles.detailsButton}
                                          variant="text"
                                          text="Szczegóły"
                                       />
                                    </Link>
                                    {!client.active && <div className={styles.placeholder} />}
                                    {client.active && (
                                       <ActionsDropdown
                                          options={
                                             client.suspension.blocked
                                                ? [
                                                     {
                                                        text: 'Odblokuj',
                                                        icon: <Ban size={20} />,
                                                        onClick: () =>
                                                           setIsChangeClientStatusModalOpen({
                                                              actionType: 'UNBLOCK',
                                                              userId: client.id,
                                                           }),
                                                     },
                                                     {
                                                        text: 'Dezaktywuj',
                                                        lastOption: true,
                                                        icon: <UserOff size={20} />,
                                                        onClick: () => {
                                                           setDeactivateModal({
                                                              open: true,
                                                              clientId: client.id,
                                                           });
                                                        },
                                                     },
                                                  ]
                                                : [
                                                     {
                                                        text: 'Dodaj zamówienie',
                                                        icon: <Plus size={20} />,
                                                        onClick: () => {
                                                           navigate(SUPPLIER_PLACE_ORDER, {
                                                              state: { clientId: client.id },
                                                           });
                                                        },
                                                     },
                                                     {
                                                        text: 'Edytuj',
                                                        icon: <Pencil size={20} />,
                                                        onClick: () => {
                                                           navigate(
                                                              SUPPLIER_EDIT_CLIENT_PATH(client.id),
                                                              {
                                                                 state: { clientId: client.id },
                                                              },
                                                           );
                                                        },
                                                     },
                                                     {
                                                        text: 'Zablokuj',
                                                        icon: <Ban size={20} />,
                                                        onClick: () =>
                                                           setIsChangeClientStatusModalOpen({
                                                              actionType: 'BLOCK',
                                                              userId: client.id,
                                                           }),
                                                     },
                                                     {
                                                        text: 'Dezaktywuj',
                                                        lastOption: true,
                                                        icon: <UserOff size={20} />,
                                                        onClick: () => {
                                                           setDeactivateModal({
                                                              open: true,
                                                              clientId: client.id,
                                                           });
                                                        },
                                                     },
                                                  ]
                                          }
                                          portalTarget={document.body}
                                       />
                                    )}
                                 </div>
                              </TableCell>
                           </TableRow>
                        ))}
                     </StyledTable>
                  </InfiniteScroll>
                  {clients.length === 0 && !loading && (
                     <NoItemsNotificationWithLink
                        icon={<UserOffIcon />}
                        desctiption="Nie dodałeś jeszcze żadnych klientów."
                        linkText="Dodaj klienta"
                        redirectTo={SUPPLIER_ADD_CLIENT}
                     />
                  )}
               </div>
            </div>
         </div>
      </>
   );
};

export default WithAuth(ClientsPage, 'ROLE_SYSTEM_ADMIN');
